import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '../store/auth';
import LoginView from "@/views/LoginView.vue"
import DashboardView from "@/views/DashboardView.vue"
import MemberView from "@/views/MemberView.vue"
import UserView from '@/views/UserView.vue';
import EpreuveView from '@/views/EpreuveView.vue';
import FAQView from '@/views/FAQView.vue';
import SiteView from '@/views/SiteView.vue';
import ExerciceView from '@/views/ExerciceView.vue';
import BiblioView from '@/views/BiblioView.vue'
import SettingsView from '@/views/SettingsView.vue';
import ActuView from '@/views/ActuView.vue';
import DetailExerciceView from '@/views/DetailExerciceView.vue'
import DetailEpreuveView from '@/views/DetailEpreuveView.vue'
import DetailDocumentView from '@/views/DetailDocumentView.vue'
import ResetView from '@/views/ResetView.vue'
import SubscriptionView from '@/views/SubscriptionView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: { requiresAuth: false },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: '/members',
    name: 'members',
    component: MemberView,
    meta: { requiresAuth: true },
  },
  {
    path: '/clients',
    name: 'clients',
    component: UserView,
    meta: { requiresAuth: true },
  },
  {
    path: '/epreuves-corriges',
    name: 'epreuves-corriges',
    component: EpreuveView,
    meta: { requiresAuth: true },
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQView,
    meta: { requiresAuth: true },
  },
  {
    path: '/site',
    name: 'site',
    component: SiteView,
    meta: { requiresAuth: true },
  },
  {
    path: '/exercices-corriges',
    name: 'exercices-corriges',
    component: ExerciceView,
    meta: { requiresAuth: true },
  },
  {
    path: '/documents',
    name: 'documents',
    component: BiblioView,
    meta: { requiresAuth: true },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/news',
    name: 'news',
    component: ActuView,
    meta: { requiresAuth: true },
  },
  {
    path: '/exercices-corriges/:id',
    name: 'details exercice',
    component: DetailExerciceView,
    meta: { requiresAuth: true },
  },
  {
    path: '/epreuves-corriges/:id',
    name: 'details epreuve',
    component: DetailEpreuveView,
    meta: { requiresAuth: true },
  },
  {
    path: '/documents/:id',
    name: 'details document',
    component: DetailDocumentView,
    meta: { requiresAuth: true },
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetView,
    meta: { requiresAuth: false },
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: SubscriptionView,
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.meta.requiresAuth && !authStore.token) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router
