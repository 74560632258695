<template>
  <section class="w-full degrade1 h-screen overflow-hidden z-0 relative flex justify-center md:p-[20px] p-[10px] items-center">
        <div class="container degrade2 max-w-[924px] h-full md:max-h-[550px] relative rounded-[10px] shadow-4xl flex flex-col md:flex-row justify-center items-center">
            <div class="md:w-6/12 relative z-10 w-full h-full md:flex hidden">
                <img src="../assets/images/admin2.jpeg" alt="">
            </div>
            <div class="md:w-6/12 w-full h-full bg-gray-100 rounded-l-[10px] overflow-y-auto md:rounded-l-none rounded-r-[10px] md:p-[50px] p-[25px] flex flex-col justify-center items-center">
              <div v-if="step === 1" class="w-full flex relative flex-col justify-center items-center">
              <h2 class="text-sm text-gray-800 w-full font-bold mb-4">Mot de passe oublié</h2>
              <form @submit.prevent="sendCode" class="flex w-full relative justify-center items-center flex-col">
                <div class="py-[10px] w-full relative">
                  <input type="email" v-model="email" id="email" class="p-[10px] w-full rounded-[5px] border outline-none bg-transparent" placeholder="E.x: serge01king@example.com" required />
                </div>
                <div class="py-[10px] w-full flex justify-end items-center relative">
                  <button type="submit" class="p-[10px] cursor-pointer rounded-[5px] flex justify-center items-center h-[45px] w-full text-sm font-bold text-white bg-blue-500 hover:bg-blue-700 duration-300" :disabled="isLoading">
                    <span v-if="!isLoading">Envoyer le code</span>
                    <div v-if="isLoading" class="spinner1">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </button>
                </div>
                <div v-if="isLoading" class="loader mt-4"></div>
                <div v-if="error" class="text-red-500 mt-4">{{ error }}</div>
              </form>
              </div>
              <div v-else-if="step === 2" class="w-full flex justify-center items-center flex-col">
                <h2 class="text-sm text-gray-800 w-full font-bold mb-4">Vérification du code</h2>
                <form @submit.prevent="verifyCode" class="flex w-full relative justify-center items-center flex-col">
                  <div class="py-[10px] w-full relative">
                    <input type="text" v-model="code" id="code" class="p-[10px] w-full rounded-[5px] border outline-none bg-transparent" placeholder="Entrez le code reçu" required />
                  </div>
                  <div class="py-[10px] w-full relative">
                    <input type="password" v-model="newPassword" id="newPassword" class="p-[10px] w-full rounded-[5px] border outline-none bg-transparent" placeholder="E.x: *********" required />
                  </div>
                  <div class="py-[10px] w-full flex justify-end items-center relative">
                    <button type="submit" class="p-[10px]  cursor-pointer rounded-[5px] h-[45px] w-full text-sm flex justify-center items-center font-bold text-white bg-blue-500 hover:bg-blue-700 duration-300" :disabled="isLoading">
                      <span v-if="!isLoading">Réinitialiser le mot de passe</span>
                      <div v-if="isLoading" class="spinner1">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </button>
                  </div>
                  <div v-if="isLoading" class="loader mt-4"></div>
                  <div v-if="error" class="text-red-500 mt-4">{{ error }}</div>
                </form>
              </div>
              <div v-else class="w-full flex justify-center items-center flex-col">
                <h2 class="text-sm text-gray-800 font-bold mb-4">Mot de passe réinitialisé avec succès</h2>
                <p class="text-sm text-center">Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.</p>
                <button @click="goToLogin()" class="mt-4 py-[10px] px-[20px] cursor-pointer rounded-[5px] text-sm font-bold text-white bg-blue-500 hover:bg-blue-700 duration-300">Retour à la connexion</button>
              </div>
                  </div>
              </div>
    </section>
</template>
  
<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const email = ref('');
const code = ref('');
const newPassword = ref('');
const isLoading = ref(false);
const error = ref(null);
const step = ref(1);
const router = useRouter();
  
const sendCode = async () => {
    isLoading.value = true;
    error.value = null;
    try {
      await axios.post('/api/send-reset-code', { email: email.value });
      step.value = 2;
    } catch (err) {
      error.value = 'Erreur lors de l\'envoi du code';
    } finally {
      isLoading.value = false;
    }
};
  
const verifyCode = async () => {
    isLoading.value = true;
    error.value = null;
    try {
      await axios.post('/api/verify-code-reset-password', { email: email.value, code: code.value, newPassword: newPassword.value });
      step.value = 3;
    } catch (err) {
      error.value = 'Erreur lors de la vérification du code';
    } finally {
      isLoading.value = false;
    }
};
  
const goToLogin = () => {
    router.push('/');
};
</script>
  
<style>
</style>
  