<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <section class="bg-blue-50 w-full h-screen overflow-y-auto flex justify-center items-start md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0">
            <div class="container px-[20px] max-w-[600px] flex flex-col justify-center relative items-center">
                <div class="w-[100%] my-[25px] bg-gray-300 flex justify-center items-center rounded-[50px]">
                    <div @click="toggleNewsletter()" class="w-6/12 duration-200 cursor-pointer text-center text-xs flex items-center justify-center p-[10px] rounded-[50px]" :class="{'bg-white font-bold': isNewsletter}">
                        <i class="fa-solid fa-envelope mx-[5px]"></i>
                        Newsletter
                    </div>
                    <div @click="toggleMessage()" class="w-6/12 duration-200 cursor-pointer text-center text-xs flex items-center justify-center text-gray-600 p-[10px] rounded-[50px]" :class="{'bg-white font-bold': isMessage}">
                        <i class="fa-solid fa-file mx-[5px]"></i>
                        Messages
                    </div>
                </div>
                <div v-if="isNewsletter" class="w-full duration-500">
                    <div v-for="(email, index) in newsletter" :key="index" class="my-[10px] cursor-pointer rounded-[10px] w-full p-[10px] flex justify-between items-center bg-white">
                        <div class="pr-[10px] h-full relative">
                            <h1 class="bg-orange-500 text-xs font-bold text-center h-full text-gray-50 flex justify-center items-center p-[5px] rounded-[5px]"># {{ index + 1 }}</h1>
                        </div>
                        <div id="creu" class="w-full relative flex flex-col justify-center border-l border-gray-400 border-dashed pl-[10px] px-[15px] items-start">
                            <h1 class="text-sm font-bold text-black">{{ email.email }}</h1>
                            <span class="text-xs text-gray-700">{{ email.date }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="isMessage" class="w-full duration-500">
                    <div v-for="(message, index) in messages" :key="index" class="my-[10px] mb-[20px] cursor-pointer rounded-[10px] w-full p-[10px] flex justify-between items-start bg-white">
                        <div class="pr-[10px] h-full flex justify-center items-start relative">
                            <div class="w-[40px] h-[40px] border-2 border-orange-600 rounded-[50%] flex justify-center items-center">
                                <i class="fa-solid fa-envelope text-orange-600 text-xl"></i>
                            </div>
                        </div>
                        <div class="w-full relative flex flex-col overflow-hidden justify-center border-l border-gray-400 border-dashed pl-[10px] px-[15px] items-start">
                            <h1 class="text-sm font-bold text-black">{{ message.prenoms }} {{ message.nom }}</h1>
                            <span class="text-xs font-semibold text-gray-500 mb-[5px]">{{ message.email }} - {{ message.numero }}</span>
                            <span class="text-xs text-gray-700">{{ message.content }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
  
<script setup>
import HeaderComponent from "../components/HeaderComponent.vue";
import { ref, onMounted, computed } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/auth';

const router = useRouter();


const messages = ref([]);
const errorMessage = ref('')
const successMessage = ref('')
const authStore = useAuthStore();
const user = computed(() => authStore.user);

const isMessage = ref(false);
const isNewsletter = ref(true);

function toggleNewsletter() {
    isNewsletter.value = true
    isMessage.value = false
}
function toggleMessage() {
    isNewsletter.value = false
    isMessage.value = true
}

const newsletter = ref([]);

const fetchNewsletter = async () => {
    try {
        const response = await axios.get('/api/newsletter/subscribers');
        newsletter.value = response.data;
    } catch (error) {
        errorMessage.value = 'Erreur lors de la récupération des emails';
    }
};

const fetchMessage = async () => {
    try {
        const response = await axios.get('/api/messages/getAll');
        messages.value = response.data;
    } catch (error) {
        errorMessage.value = 'Erreur lors de la récupération des messages';
    }
};
/* 
onMounted(() => {
    fetchNewsletter();
    fetchMessage();
    if(!user.value.isSuper) {
      router.push("/dashboard")
    }
}); */

</script>
  
<style scoped>
#creu::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #e5e7eb;
    position: absolute;
    top: -17px;
    left: -5px;
}
#creu::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #e5e7eb;
    position: absolute;
    bottom: -17px;
    left: -5px;
}
</style>
  