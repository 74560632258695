<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] md:left-[60%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 w-full h-screen overflow-y-auto md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0">
            <div class="w-full flex flex-col md:mt-0 mt-[20px] justify-between items-start relative">
                <h1 class="text-gray-800 md:text-2xl font-bold">Liste des utilisateurs de la plateforme</h1>
                <div class="flex w-full flex-col relative mt-[25px]">
                    <ul class="w-full relative">
                        <li class="w-full relative flex justify-center items-center p-[5px] bg-gray-200">
                            <div class="p-[5px] font-bold text-sm text-gray-800 w-[50px] min-w-[50px] hidden md:flex">ID</div>
                            <div class="p-[5px] font-bold text-sm text-gray-800 w-full">Nom complet</div>
                            <div class="p-[5px] font-bold text-sm text-gray-800 w-[25%] min-w-[25%] hidden md:flex">Email</div>
                            <div class="p-[5px] font-bold text-sm text-gray-800 w-[80px] min-w-[80px]">Numéro</div>
                            <div class="p-[5px] font-bold text-sm text-gray-800 w-[80px] min-w-[80px]">Forfait</div>
                        </li>
                        <li v-for="(client, index) in paginatedClients" :key="index" class="w-full relative flex justify-center items-center p-[5px] bg-white border border-t-0">
                            <div class="p-[5px] text-sm text-gray-600 w-[50px] min-w-[50px] hidden md:flex">#{{ index + 1 }}</div>
                            <div class="p-[5px] text-sm text-gray-600 w-full">{{ client.firstname }} {{ client.lastname }}</div>
                            <div class="p-[5px] text-sm text-gray-600 w-[25%] min-w-[25%] overflow-hidden hidden md:flex">{{ client.email }}</div>
                            <div class="p-[5px] text-sm text-gray-600 w-[80px] min-w-[80px]">{{ client.phone ? client.phone : "Non défini" }}</div>
                            <div v-if="client.subscription" class="p-[5px] text-sm text-gray-600 w-[80px] min-w-[80px]">
                                <div v-if="client.subscription == '66dbc2023c588a48f7c65fec' || client.subscription == '66dbc23a3c588a48f7c65fee'">
                                <i class="fa-solid fa-star mr-[5px] text-blue-600"></i>
                                <i class="fa-regular fa-star mr-[5px] text-blue-600"></i>
                                </div>
                                <div v-if="client.subscription == '66dc16f63c588a48f7c65ff6' || client.subscription == '66dc171e3c588a48f7c65ff8'">
                                <i class="fa-solid fa-star mr-[5px] text-blue-600"></i>
                                <i class="fa-solid fa-star mr-[5px] text-blue-600"></i>
                                </div>
                            </div>
                            <div v-else class="p-[5px] text-sm text-gray-600 w-[80px] min-w-[80px]">
                                SA
                            </div>
                        </li>
                    </ul>
                    <div class="md:py-[50px] py-[25px] flex w-full md:justify-end justify-center items-center px-[20px]">
                        <div v-for="page in totalPages" :key="page" @click="goToPage(page)" 
                        :class="{'bg-blue-950 text-white border border-blue-950': currentPage === page, 'bg-white': currentPage !== page}" 
                        class="w-[30px] h-[30px] mx-[5px] rounded-[5px] shadow-4xl cursor-pointer border flex justify-center items-center font-bold text-sm text-center">
                            {{ page }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isLoading && clients.length === 0" class="w-full h-[200px] flex justify-center items-center text-center text-gray-600">
                Aucun utilisateur trouvé.
            </div>
            <div v-if="isLoading" class="w-full h-[200px] flex justify-center items-center">
                <div class="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </section>
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { ref, computed, onMounted } from 'vue'
import axios from "axios"

const isAjouter = ref(false)
function toggleAjout() {
    isAjouter.value = !isAjouter.value
}
const clients = ref([])
const errorMessage = ref('')
const successMessage = ref('')
const lastname = ref('')
const firstname = ref('')
const phone = ref('')
const poste = ref('')
const email = ref('')
const isLoading = ref('')

const fetchUtilisateurs = async() => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/client')
        clients.value = response.data
    } catch {
        errorMessage.value = "Erreur lors du chargement des utilisateurs"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const currentPage = ref(1);
const itemsPerPage = ref(20);
const totalPages = computed(() => Math.ceil(clients.value.length / itemsPerPage.value));

const paginatedClients = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return clients.value.slice(start, end);
});

const goToPage = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= totalPages.value) {
    currentPage.value = pageNumber;
  }
};

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    fetchUtilisateurs()
})

</script>

<style>
</style>