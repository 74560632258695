<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] md:left-[60%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 w-full h-screen overflow-y-auto md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0">
            <div class="w-full relative flex justify-center items-center flex-col">
                <h1 class="text-center text-gray-800 md:text-2xl text-sm font-bold">
                    Ajoutez des informations au <span class="text-red-600 underline">F.A.Q</span>
                </h1>
                <div class="my-[20px] w-full relative">
                    <form @submit.prevent="submitForm()">
                        <div class="w-full relative my-[10px]">
                            <input type="text" required v-model="question" class="p-[10px] rounded-[5px] w-full outline-none bg-transparent border border-gray-300 text-sm" placeholder="Question">
                        </div>
                        <div class="w-full relative my-[10px]">
                            <textarea v-model="reponse" required class="p-[10px] rounded-[5px] h-[100px] resize-none w-full outline-none bg-transparent border border-gray-300 text-sm" placeholder="Réponse"></textarea>
                        </div>
                        <div class="my-[10px] w-full relative">
                            <button type="submit" v-if="!modification" class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                                <span v-if="!isLoading">Ajouter</span>
                                <div v-if="isLoading" class="spinner1">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </button>
                            <button type="submit" v-if="modification" class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                                <span v-if="!isLoading">Modifier</span>
                                <div v-if="isLoading" class="spinner1">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="w-full relative mt-[20px]">
                    <div v-for="(faq, index) in faqs" :key="index" class="w-full bg-white cursor-pointer mb-[30px] relative border rounded-[20px] p-[20px]">
                        <div @click="toggleContent(faq._id)" class="w-full flex justify-between items-center relative">
                            <h1 class="text-sm font-bold text-gray-800">{{ faq.question }}</h1>
                            <button>
                                <i class="fa-solid fa-chevron-down"></i>
                            </button>
                        </div>
                        <div v-if="showContent === faq._id" class="w-full border-t mt-[10px] pt-[10px] relative">
                            <p class="text-sm text-gray-600 font-semibold">
                                {{ faq.reponse }}
                            </p>
                        </div>
                        <div class="absolute left-[0px] top-[-20px] h-[40px] flex justify-center items-center">
                            <div @click="toggleModification(faq)" class="w-[30px] h-[30px] z-40 cursor-pointer mx-[5px] shadow-4xl rounded-[50px] flex justify-center items-center bg-white text-blue-600 text-sm font-bold"><i class="fa-solid fa-pen"></i></div>
                            <div @click="deleteFAQ(faq._id)" class="w-[30px] h-[30px] z-40 cursor-pointer mx-[5px] shadow-4xl rounded-[50px] flex justify-center items-center bg-white text-red-600 text-sm font-bold"><i class="fa-solid fa-trash"></i></div>
                        </div>
                    </div>
                </div>
                <div v-if="!isLoading && faqs.length === 0" class="w-full h-[200px] flex justify-center items-center text-center text-gray-600">
                    Aucune information dans la F.A.Q.
                </div>
                <div v-if="isLoading1" class="w-full h-[200px] flex justify-center items-center">
                    <div class="spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'

const question = ref('')
const reponse = ref('')
const successMessage = ref('')
const errorMessage = ref('')
const isLoading = ref(false)
const isLoading1 = ref(false)
const faqs = ref([])
const showContent = ref(null)
const modification = ref(false)
const faqId = ref('')

function toggleContent(id) {
    showContent.value = showContent.value === id ? null : id
}

function toggleModification (faq) {
    modification.value = true,
    question.value = faq.question,
    reponse.value = faq.reponse,
    faqId.value = faq._id

}

const fetchFAQ = async () => {
    isLoading1.value = true
    try {
        const response = await axios.get('/api/faqs')
        faqs.value = response.data
    } catch (error) {
        errorMessage.value = 'Erreur lors du chargement des informations de la FAQ'
        hideErrorMessageAfterDelay()
    } finally {
        isLoading1.value = false
    }
}

const submitFAQ = async () => {
    isLoading.value = true
    try {
        const response = await axios.post('/api/faqs', {
            question: question.value,
            reponse: reponse.value,
        });
        if ( response.status == 200 || response.status == 201 ) {
            successMessage.value = 'Nouvel ajout validé !'
            hideSuccessMessageAfterDelay()
            fetchFAQ()
        } else {
            errorMessage.value = 'Erreur inattendue lors de l\'ajout d\'information au F.A.Q'
            hideErrorMessageAfterDelay()
        }
        question.value = ""
        reponse.value = ""
    } catch (error) {
        errorMessage.value = 'Erreur lors de l\'ajout de la question réponse'
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const submitModificationFAQ = async () => {
    isLoading.value = true
    try {
        const response = await axios.put(`/api/faqs/${faqId.value}`, {
            question: question.value,
            reponse: reponse.value,
        });
        if ( response.status == 200 || response.status == 201 ) {
            successMessage.value = 'Informations modifié avec succès !'
            hideSuccessMessageAfterDelay()
            fetchFAQ()
        } else {
            errorMessage.value = 'Erreur inattendue lors de la modification de l\'information au F.A.Q'
            hideErrorMessageAfterDelay()
        }
        question.value = ""
        reponse.value = ""
        modification.value = false
    } catch (error) {
        errorMessage.value = 'Erreur lors de la modification de la question réponse'
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const submitForm = async () => {
   if (modification.value) {
       await submitModificationFAQ();
   } else {
       await submitFAQ();
   }
};

const deleteFAQ = async (id) => {
  try {
    await axios.delete(`/api/faqs/${id}`);
    successMessage.value = 'Information supprimée avec succès '
    hideSuccessMessageAfterDelay()
    fetchFAQ()
  } catch (error) {
    errorMessage.value = 'Erreur lors de la suppression de l\'information.';
    hideErrorMessageAfterDelay()
  }
};


const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    fetchFAQ()
})
</script>

<style>

</style>