<template>
    <div class="fixed top-[20px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
        <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
            {{ errorMessage }}
        </div>
    </div>
    <section class="w-full degrade1 h-screen overflow-hidden z-0 relative flex justify-center md:p-[20px] p-[10px] items-center">
        <div class="container degrade2 max-w-[924px] h-full overflow-hidden md:max-h-[550px] relative rounded-[10px] shadow-4xl flex flex-col md:flex-row justify-center items-center">
            <div class="md:w-6/12 hidden md:flex bg-white relative z-10 w-full h-full">
                <img src="../assets/images/admin2.jpeg" alt="" class="w-full h-full object-contain object-center">
            </div>
            <div class="md:w-6/12 w-full h-full bg-gray-100 rounded-l-[10px] md:rounded-l-none rounded-r-[10px] md:p-[50px] p-[25px] flex flex-col justify-center md:justify-start items-center">
                <h1 class="font-bold text-center text-gray-800">
                    Salut 🤗, Ravi de vous revoir
                </h1>
                <p class="text-xs text-center my-[15px] font-semibold text-gray-500">
                    Panel d'administration privé
                </p>
                <form action="" @submit.prevent="login()" class="my-[10px] w-full relative">
                    <div class="w-full relative my-[15px]">
                        <input type="text" v-model="email" id="email" :class="{'not-empty border-green-600': email.trim() !== ''}" class="w-full p-[10px] bg-transparent focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                        <label for="email" class="text-sm font-bold text-gray-500 absolute top-[10px] left-[10px] label">Entrez votre Email</label>
                    </div>
                    <div class="w-full relative my-[15px]">
                        <input type="password" v-model="password" id="password" :class="{'not-empty border-green-600': password.trim() !== ''}" class="w-full p-[10px] bg-transparent focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                        <label for="password" class="text-sm font-bold text-gray-500 absolute top-[10px] left-[10px] label">Entrez votre Mot de passe</label>
                    </div>
                    <div class="w-full relative my-[15px]">
                        <button type="submit" class="w-full p-[10px] bg-blue-500 hover:bg-blue-600 duration-200 flex justify-center items-center h-[45px] text-center font-bold rounded-[5px] text-sm text-white">
                            <span v-if="!isLoading">Se connecter</span>
                            <div v-if="isLoading" class="spinner1">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                        </button>
                    </div>
                    <div class="my-[15px] relative w-full flex justify-end">
                        <button @click="gotoReset()" class="text-gray-800 font-bold text-sm hover:underline">Mot de passe oublié ?</button>
                    </div>
                    
                    <div class="py-[15px] relative w-full flex justify-start items-center border-t border-b border-gray-200">
                        <input type="checkbox" name="" id="">
                        <label class="text-gray-600 text-xs font-bold ml-[5px]">Garder ma session ouverte</label>
                    </div>
                    <div class="my-[10px] relative w-full flex justify-end">
                        <p class="text-[10px] w-full my-[15px] font-semibold text-gray-500">
                            En utilisant notre plateforme, vous acceptez nos <button class="text-blue-500 hover:underline"> Conditions d'utilisation </button> et consentez à notre <button class="text-blue-500 hover:underline"> Politique de confidentialité </button>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useAuthStore } from '@/store/auth';
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const email = ref('');
const password = ref('');
const isLoading = ref(false);
const error = ref(null);
const router = useRouter();
const errorMessage = ref('')

function gotoReset() {
    router.push('/reset')
}
const authStore = useAuthStore();
const login = async () => {
  isLoading.value = true;
  error.value = null;
  try {
    const response = await axios.post('/api/login', { email: email.value, password: password.value });
    authStore.setToken(response.data.token);
    authStore.setUser(response.data.user);
    router.push("/dashboard")
  } catch (err) {
    if (err.response && err.response.data && err.response.data.error) {
        errorMessage.value = err.response.data.error;
    } else {
        errorMessage.value = 'Erreur lors de la connexion';
    }
  } finally {
    isLoading.value = false;
  }
};
</script>

<style>

</style>