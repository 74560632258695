<template>
  <router-view/>
</template>

<style lang="scss">
.clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.parent::-webkit-scrollbar {
    width: 0%;
    height: 0%;
}
.degrade1 {
    background: linear-gradient(to top left, #f3f4f6, #dbeafe, #bfdbfe, rgb(191 219 254 / 0.5));
}
.degrade3 {
    background: linear-gradient(to right, rgb(54, 54, 152), rgb(157, 21, 157), rgba(219, 60, 219, 0.799), rgba(196, 65, 243, 0.888));
}
.hoverr:hover {
    background: linear-gradient(to right, rgb(54, 54, 152), rgb(157, 21, 157), rgba(219, 60, 219, 0.799), rgba(196, 65, 243, 0.888));
}
.degrade2::before {
    content: '';
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #93c5fd;
    position: absolute;
    bottom: -100px;
    left: -100px;
    z-index: -10;
}
.label {
    transition: 0.2s;
}
.input-text:focus ~ .label {
    position: absolute;
    top: -10px;
    background: #f3f4f6;
    padding: 0px 5px;
    font-size: 12px;
}
.not-empty ~ .label {
    position: absolute;
    top: -10px;
    background: #f3f4f6;
    padding: 0px 5px;
    font-size: 12px;
}
.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.spinner {
   position: relative;
   width: 10px;
   height: 10px;
}

.spinner div {
   animation: spinner-4t3wzl 1.875s infinite backwards;
   background-color: rgb(31, 41, 55);
   border-radius: 50%;
   height: 100%;
   position: absolute;
   width: 100%;
}

.spinner div:nth-child(1) {
   animation-delay: 0.15s;
   background-color: rgb(31, 41, 55, 0.9);
}

.spinner div:nth-child(2) {
   animation-delay: 0.3s;
   background-color: rgb(31, 41, 55,0.8);
}

.spinner div:nth-child(3) {
   animation-delay: 0.45s;
   background-color: rgb(31, 41, 55,0.7);
}

.spinner div:nth-child(4) {
   animation-delay: 0.6s;
   background-color: rgb(31, 41, 55,0.6);
}

.spinner div:nth-child(5) {
   animation-delay: 0.75s;
   background-color: rgb(31, 41, 55,0.5);
}

@keyframes spinner-4t3wzl {
   0% {
      transform: rotate(0deg) translateY(-200%);
   }

   60%, 100% {
      transform: rotate(360deg) translateY(-200%);
   }
}

.spinner1 {
   position: relative;
   width: 5px;
   height: 5px;
}

.spinner1 div {
   animation: spinner-4t3wzl1 1.875s infinite backwards;
   background-color: #fff;
   border-radius: 50%;
   height: 100%;
   position: absolute;
   width: 100%;
}

.spinner1 div:nth-child(1) {
   animation-delay: 0.15s;
   background-color: rgba(255, 255, 255, 0.9);
}

.spinner1 div:nth-child(2) {
   animation-delay: 0.3s;
   background-color: rgba(255,255,255,0.8);
}

.spinner1 div:nth-child(3) {
   animation-delay: 0.45s;
   background-color: rgba(255,255,255,0.7);
}

.spinner1 div:nth-child(4) {
   animation-delay: 0.6s;
   background-color: rgba(255,255,255,0.6);
}

.spinner1 div:nth-child(5) {
   animation-delay: 0.75s;
   background-color: rgba(255,255,255,0.5);
}

@keyframes spinner-4t3wzl1 {
   0% {
      transform: rotate(0deg) translateY(-200%);
   }

   60%, 100% {
      transform: rotate(360deg) translateY(-200%);
   }
}
</style>
