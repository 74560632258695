<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] md:left-[60%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 w-full h-screen overflow-y-auto md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0">
            <div class="w-full relative flex md:justify-end mt-[20px] md:mt-0 justify-center items-center">
                <button @click="toggleAjout()" class="p-[10px] text-center bg-black rounded-[5px] flex items-center justify-center text-gray-200 font-bold text-sm w-full md:w-auto">
                    Ajouter un nouveau membre
                </button>
            </div>
            <div class="w-full flex md:mt-[50px] mt-[20px] justify-between items-start flex-wrap relative">
                <div v-for="(member, index) in members" :key="index" class="bg-white lg:w-[calc(100%/4-10px)] md:w-[calc(100%/2-10px)] w-full my-[10px] relative shadow-4xl rounded-[10px] h-[200px]">
                    <div class="w-full h-1/2 bg-[#151036] rounded-t-[10px]">

                    </div>
                    <div class="w-full h-1/2 relative">
                        <div class="w-[60px] h-[60px] rounded-[50px] flex justify-center items-center bg-white mt-[-30px] left-[50%] translate-x-[-50%] shadow-7xl overflow-hidden relative">
                            <img src="../assets/images/icone.png" alt="" class="absolute object-contain object-center w-full h-full">
                        </div>
                        <div class="w-full flex flex-col justify-center items-center">
                            <h1 class="text-center text-sm font-bold text-gray-800">
                                {{ member.firstname }} {{ member.lastname }}
                            </h1>
                            <p class="text-center text-[10px] font-semibold text-gray-600">
                                {{ member.email }}
                            </p>
                            <p class="text-center text-xs font-semibold text-gray-500">
                                + 229 {{ member.phone }} | {{ member.poste   }}
                            </p>
                        </div>
                    </div>
                    <div class="absolute top-[20px] z-30 left-[-10px] rounded-[5px] p-[5px] bg-white shadow-7xl text-sm font-bold text-gray-800">
                        {{ member.username }}
                    </div>
                </div>
            </div>
            <div v-if="!isLoading && members.length === 0" class="w-full h-[200px] flex justify-center items-center text-center text-gray-600">
                Aucun utilisateur trouvé.
            </div>
            <div v-if="isLoading" class="w-full h-[200px] flex justify-center items-center">
                <div class="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div v-if="isAjouter" class="md:w-[calc(100%-250px)] w-full md:left-[250px] z-50 left-0 top-0 h-full bg-black/40 fixed">
                <div class="absolute md:top-[50%] top-[55%] translate-y-[-50%] left-[50%] p-[10px] translate-x-[-50%] max-h-[80%] h-auto w-[90%] max-w-[400px] bg-white rounded-[10px]">
                    <div class="w-full sticky top-0 py-[10px] text-sm font-bold text-center text-gray-800">
                        Ajouter un nouveau membre
                    </div>
                    <form @submit.prevent="submitMember()" class="w-full flex py-[10px] relative flex-col">
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="lastname" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Nom">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="firstname" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Prénoms">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="email" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Email">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="phone" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Numéro">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="poste" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Poste">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <button type="submit" class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                                <span v-if="!isLoading">Ajouter</span>
                                <div v-if="isLoading" class="spinner1">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </button>
                        </div>
                    </form>
                    <div @click="toggleAjout()" class="w-[30px] h-[30px] absolute bottom-[-40px] cursor-pointer left-[50%] translate-x-[-50%] rounded-[50%] bg-gray-300 flex justify-center items-center">
                        <i class="fa-solid fa-times"></i>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { ref, computed, onMounted } from 'vue'
import axios from "axios"

const isAjouter = ref(false)
function toggleAjout() {
    isAjouter.value = !isAjouter.value
}
const members = ref([])
const errorMessage = ref('')
const successMessage = ref('')
const lastname = ref('')
const firstname = ref('')
const phone = ref('')
const poste = ref('')
const email = ref('')
const isLoading = ref('')

const fetchMembers = async() => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/users')
        members.value = response.data
    } catch {
        errorMessage.value = "Erreur lors du chargement des membres"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const submitMember = async () => {
    isLoading.value = true
    try {
        const response = await axios.post('/api/users', {
            lastname: lastname.value,
            firstname: firstname.value,
            phone: phone.value,
            poste: poste.value,
            email: email.value
        });

        if (response.status === 200 || response.status === 201) {
            successMessage.value = "Utilisateur créé avec succès";
            hideSuccessMessageAfterDelay()
            fetchMembers();
            isAjouter.value = false;
        } else {
            errorMessage.value = "Erreur inattendue lors de la création de l'utilisateur";
            hideErrorMessageAfterDelay()
        }
        lastname.value = ''
        firstname.value = ''
        phone.value = ''
        poste.value = ''
        email.value = ''
    } catch (error) {
        errorMessage.value = "Erreur lors de l'ajout du membre";
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
};

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    fetchMembers()
})

</script>

<style>
</style>