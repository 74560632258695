<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 parent w-full h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="w-full relative flex justify-center items-center flex-col">
                <div class="mb-[25px] w-full relative flex flex-col">
                    <div class="w-full relative flex my-[10px] justify-between items-center">
                        <div class="flex flex-col">
                            <h1 class="text-sm clamp font-bold text-gray-800">{{ exercice.matiere }}</h1>
                            <p class="text-[10px] clamp font-semibold text-gray-500">
                                {{ exercice.titre }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full parent relative flex justify-center items-center flex-col">
                <div class="w-full flex justify-center items-center relative mt-[10px]">
                    <div id="pdf-container" @contextmenu.prevent></div>
                </div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { useAuthStore } from '@/store/auth';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const router = useRouter()
const exerciceId = router.currentRoute.value.params.id;
const exercice = ref({})
const errorMessage = ref('')
const successMessage = ref('')

const fetchexerciceDetail = async() => {
    try {
        const response = await axios.get(`/api/documents/${exerciceId}`)
        exercice.value = response.data
        displayPDF();
    } catch (error) {
        console.log('Erreur lors du chargement du document')
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    fetchexerciceDetail()
})

const displayPDF = () => {
    const container = document.getElementById('pdf-container');
    container.innerHTML = ''; // Vide le conteneur avant de charger les pages

    const correctedPdfUrl = `https://api.revixo-learn.online/${exercice.value.content.replace(/\\/g, '/')}`;

    pdfjsLib.getDocument(correctedPdfUrl).promise.then(pdf => {
        const containerWidth = container.clientWidth;
        const pixelRatio = window.devicePixelRatio || 1; // Récupère la densité de pixels de l'écran

        // Affiche toutes les pages
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            pdf.getPage(pageNumber).then(page => {
                // Calculer l'échelle pour s'adapter à la largeur du conteneur
                const viewport = page.getViewport({ scale: 1 });
                const scale = containerWidth / viewport.width;
                const scaledViewport = page.getViewport({ scale: scale });

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                
                // Ajuster la résolution pour une meilleure netteté
                canvas.height = scaledViewport.height * pixelRatio;
                canvas.width = scaledViewport.width * pixelRatio;
                canvas.style.height = `${scaledViewport.height}px`;  // Taille CSS
                canvas.style.width = `${scaledViewport.width}px`;    // Taille CSS

                // Désactive le clic droit et la sélection de texte
                canvas.style.pointerEvents = 'none';
                canvas.style.userSelect = 'none';

                // Rendre la page sur le canvas en tenant compte du ratio de pixels
                context.scale(pixelRatio, pixelRatio);
                page.render({ canvasContext: context, viewport: scaledViewport }).promise.then(() => {
                    container.appendChild(canvas);
                });
            });
        }
    }).catch(error => {
        errorMessage.value = 'Erreur lors de l\'affichage du PDF';
        hideErrorMessageAfterDelay();
    });
};

</script>

<style scoped>
#pdf-container-corrige {
    user-select: none;
    pointer-events: none;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
#pdf-container {
    user-select: none;
    pointer-events: none;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
canvas {
  pointer-events: none;
  width: 100%;
}
</style>