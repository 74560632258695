<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] md:left-[60%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 w-full h-screen overflow-y-auto md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0">
            <div class="w-full relative flex justify-between items-center">
                <div class="flex flex-col">
                    <h1 class="text-blue-950 md:text-3xl text-lg">Bienvenue {{ user.firstname }} !</h1>
                    <p class="text-sm text-gray-500 ">Ici vous trouverez un récapitulatif général de vos activités</p>
                </div>
            </div>
            <div class="w-full relative flex justify-between items-center flex-wrap mt-[25px]">
                <div class="w-full md:h-[400px] h-auto flex flex-col justify-center items-center relative shadow-7xl bg-white p-[10px] md:p-[20px] rounded-[10px] my-[10px]">
                    <div class="chart-container">
                        <canvas id="myChart" ref="myChart"></canvas>
                    </div>
                </div>
            </div>
            <div class="w-full mt-[25px] py-[25px]">
                <h1 class="text-2xl font-bold mb-[15px] text-gray-800">Les filières disponibles</h1>
                <div class="w-full relative flex lg:justify-start justify-between flex-wrap items-center">
                    <div v-for="(filiere, index) in filieres" :key="index" class="h-[150px] lg:w-[220px] w-[calc(50%-10px)] min-h-[150px] lg:mr-[20px] rounded-[25px] bg-gray-800 relative my-[10px] flex justify-end items-center flex-col">
                        <div class="w-[calc(100%-30px)] min-h-[50px] h-auto p-[10px] rounded-[10px] flex flex-col justify-center items-center my-[15px] z-10 bg-white/70">
                            <h1 class="text-sm font-bold text-gray-800 text-center">{{ filiere.nom }} ( <span v-for="(year, index) in filiere.annee" :key="index" class="mr-[5px]">
                                {{ year }}
                            </span> )</h1>
                            <p class="text-[10px] text-gray-500 font-semibold text-center">Etudiants sur la plateforme</p>
                        </div>
                        <button @click="modificationFiliere(filiere)" class="w-[30px] h-[30px] rounded-[50%] bg-blue-50 flex justify-center items-center absolute right-[70px] top-[-15px]">
                          <i class="fa-solid fa-pen text-gray-800 text-sm"></i>
                        </button>
                        <button @click="deleteFiliere(filiere._id)"  class="w-[30px] h-[30px] rounded-[50%] bg-blue-50 flex justify-center items-center absolute right-[20px] top-[-15px]">
                          <i class="fa-solid fa-trash text-gray-800 text-sm"></i>
                        </button>
                    </div>
                    <div class="md:h-[150px] h-[150px] lg:w-[220px] w-[calc(50%-10px)] md:min-h-[150px] min-h-[150px] cursor-pointer lg:min-w-[220px] lg:mr-[20px] my-[10px] rounded-[25px] bg-gray-800 relative overflow-hidden flex justify-end items-center flex-col">
                        <div @click="toggleAjout()" class="w-[calc(100%-30px)] min-h-[50px] h-full p-[10px] rounded-[10px] flex flex-col justify-center items-center my-[15px] z-10 bg-white/50">
                            <i class="fa-solid fa-add text-3xl text-white"></i>
                            <h1 class="text-white text-sm font-bold">Ajouter</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isAjouter" class="md:w-[calc(100%-250px)] w-full md:left-[250px] z-50 left-0 top-0 h-full bg-black/40 fixed">
                <div class="absolute md:top-[50%] top-[55%] translate-y-[-50%] left-[50%] p-[10px] translate-x-[-50%] max-h-[80%] h-auto w-[90%] max-w-[400px] bg-white rounded-[10px]">
                    <div class="w-full sticky top-0 py-[10px] text-sm font-bold text-center text-gray-800">
                        <span v-if="!modification">
                          Ajouter une filière
                        </span>
                        <span v-if="modification">
                          Modifier une filière
                        </span>
                    </div>
                    <form @submit.prevent="submitForm()" class="w-full flex py-[10px] relative flex-col">
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="nom" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Filière">
                        </div>
                    
                        <!-- Section pour ajouter plusieurs années -->
                        <div class="my-[10px] w-full relative">
                            <label class="block text-gray-600 mb-[5px]">Années</label>
                            <div v-for="(an, index) in annees" :key="index" class="flex items-center space-x-[10px] mb-[10px]">
                                <input type="text" v-model="annees[index]" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Année">
                                <button @click.prevent="removeAnnee(index)" type="button" class="text-red-500">
                                    <i class="fa-solid fa-minus"></i>
                                </button>
                            </div>
                            <button @click.prevent="addAnnee" type="button" class="text-blue-500">
                                <i class="fa-solid fa-plus"></i> Ajouter une année
                            </button>
                        </div>
                    
                        <div class="my-[10px] w-full relative">
                            <button type="submit" class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                                <span v-if="!isLoading && !modification">Ajouter</span>
                                <span v-if="!isLoading && modification">Modifier</span>
                                <div v-if="isLoading" class="spinner1">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </button>
                        </div>
                    </form>
                    <div @click="toggleAjout()" class="w-[30px] h-[30px] absolute bottom-[-40px] cursor-pointer left-[50%] translate-x-[-50%] rounded-[50%] bg-gray-300 flex justify-center items-center">
                        <i class="fa-solid fa-times"></i>
                    </div>
                </div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { ref, computed, onMounted } from 'vue'
import { Chart, registerables } from 'chart.js';
import { useAuthStore } from '@/store/auth';
import axios from 'axios'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale, Filler } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale, Filler);

Chart.register(...registerables);

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const isAjouter = ref(false)
const myChart = ref(null);
const filteredSales = ref([])
let chart;
const clients = ref([])
const errorMessage = ref('')
const successMessage = ref('')
const isLoading = ref(false)
const nom = ref('')
const annee = ref('')

function toggleAjout() {
  isAjouter.value = !isAjouter.value
}

const fetchUtilisateurs = async() => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/client')
        clients.value = response.data
        createChart()
    } catch {
        errorMessage.value = "Erreur lors du chargement des utilisateurs"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const modification = ref(false)
const filiereId = ref('')

function modificationFiliere(filiere) {
    isAjouter.value = true;      // Ouvre la fenêtre d'ajout
    modification.value = true;   // On est en mode modification
    nom.value = filiere.nom;     // Remplir le nom de la filière
    annees.value = [...filiere.annee]; // Remplir les années sous forme de tableau
    filiereId.value = filiere._id;     // ID de la filière à modifier
}

const annees = ref(['']);

const addAnnee = () => {
    annees.value.push(''); // Ajouter une nouvelle entrée vide pour l'année
};

const removeAnnee = (index) => {
    if (annees.value.length > 1) {
        annees.value.splice(index, 1); // Supprimer l'entrée de l'année à l'index donné
    }
};

const submitFiliere = async() => {
    isLoading.value = true;
    try {
        const response = await axios.post(`/api/filieres`, {
            nom: nom.value,
            annee: annees.value, // Envoyer les années comme un tableau
        });
        if (response.status == 200 || response.status == 201) {
            successMessage.value = "Filière ajoutée avec succès";
            hideSuccessMessageAfterDelay();
            fetchFilieres()
        } else {
            errorMessage.value = "Erreur inattendue lors de l'ajout de la filière";
            hideErrorMessageAfterDelay();
        }
        resetForm();
    } catch {
        errorMessage.value = "Erreur lors de l'ajout de la filière";
        hideErrorMessageAfterDelay();
    } finally {
        isLoading.value = false;
    }
};

const submitModificationFiliere = async() => {
    isLoading.value = true;
    try {
        const response = await axios.put(`/api/filieres/${filiereId.value}`, {
            nom: nom.value,
            annee: annees.value, // Envoyer les années comme un tableau
        });
        if (response.status == 200 || response.status == 201) {
            successMessage.value = "Filière modifiée avec succès";
            hideSuccessMessageAfterDelay();
            fetchFilieres();
        } else {
            errorMessage.value = "Erreur inattendue lors de la modification de la filière";
            hideErrorMessageAfterDelay();
        }
        resetForm();
        modification.value = false;
        isAjouter.value = false;
    } catch {
        errorMessage.value = "Erreur lors de la modification de la filière";
        hideErrorMessageAfterDelay();
    } finally {
        isLoading.value = false;
    }
};

const submitForm = async () => {
    if (modification.value) {
        await submitModificationFiliere();
    } else {
        await submitFiliere();
    }
};

const resetForm = () => {
    nom.value = '';
    annees.value = ['']; // Réinitialiser le tableau des années
};

const filieres = ref([])
const fetchFilieres = async() => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/filieres')
        filieres.value = response.data
    } catch {
        errorMessage.value = "Erreur lors du chargement des filières"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const createChart = () => {
  const ctx = document.getElementById('myChart').getContext('2d');

  if (chart) {
    chart.destroy();
  }

  const onlineData = Array(12).fill(0);

  clients.value.forEach(client => {
    const month = new Date(client.createdAt).getMonth();
    onlineData[month] += 1;
  });

  chart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: 'Utilisateurs',
          borderColor: '#36A2EB',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          pointBackgroundColor: 'rgba(54, 162, 235, 1)',
          pointBorderColor: '#fff',
          data: onlineData,
          fill: true,
          tension: 0.4
        }
      ]
    }
  });
};

const deleteFiliere = async (id) => {
  try {
    await axios.delete(`/api/filieres/${id}`);
    fetchFilieres()
  } catch (error) {
    errorMessage.value = 'Erreur lors de la suppression de la filière.';
    hideErrorMessageAfterDelay()
  }
};

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(async () => {
     createChart()
});
onMounted(() => {
  fetchUtilisateurs()
  fetchFilieres()
});
</script>

<style>

.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>