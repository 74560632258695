<template>
  <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
      <HeaderComponent />
      <div class="fixed md:top-[20px] top-[80px] left-[50%] md:left-[60%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
          <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
              {{ errorMessage }}
          </div>
          <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
              {{ successMessage }}
          </div>
      </div>
      <section class="bg-blue-50 w-full h-screen overflow-y-auto md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0">
            <div class="w-full relative flex md:justify-end mt-[20px] md:mt-0 justify-center items-center">
                <button @click="toggleAjout()" class="p-[10px] text-center bg-black rounded-[5px] flex items-center justify-center text-gray-200 font-bold text-sm w-full md:w-auto">
                    Ajouter un document
                </button>
            </div>
            <div class="w-full relative my-[10px]">
                <input type="text" v-model="searchQuery" class="p-[10px] relative w-full text-sm border bg-transparent outline-none" placeholder="Rechercher par titre, matière, filière ou année"/>
            </div>
          <div class="w-full flex md:mt-[50px] mt-[20px] justify-between items-start flex-wrap relative">
              <div class="w-full">
                  <div v-for="(document, index) in paginatedDocument" :key="index" :class="{'mb-[50px]': showBoxId === document._id}" class="w-full relative md:px-[20px] px-[10px] cursor-pointer my-[5px] py-[10px] rounded-[5px] bg-white flex justify-between items-center">
                      <div class="flex items-center md:w-auto w-[calc(100%-80px)]">
                          <div class="w-[35px] h-[40px] min-w-[35px] min-h-[40px] relative overflow-hidden flex justify-center items-center">
                              <img src="../assets/images/epreuve.jpeg" alt="" class="w-full h-full absolute object-contain">
                          </div>
                          <router-link class="flex flex-col ml-[10px]" :to="'/documents/'+ document._id">
                              <h1 class="text-sm clamp font-bold text-gray-800">{{ document.matiere }}</h1>
                              <p class="text-[10px] clamp font-semibold hover:underline text-gray-500">
                                  {{ document.titre }}
                              </p>
                          </router-link>
                      </div>
                      <div class="flex items-center">
                          <div class="p-[10px] rounded-[5px] bg-gray-100 flex text-[10px] font-bold text-gray-600">
                              <div class="hidden md:flex items-center">
                                  <i class="fa-solid fa-calendar pr-[5px]"></i>
                                  <span>{{ document.createdAt }}</span>
                              </div>
                              <span class="mx-[5px] hidden md:flex">|</span>
                              <div class="flex items-center">
                                  <i class="fa-solid fa-star pr-[5px]"></i>
                                  <span>5</span>
                              </div>
                          </div>
                          <button @click="toggleBox(document._id)" class="ml-[10px] w-[20px]">
                              <i v-if="showBoxId !== document._id" class="fa-solid fa-ellipsis-vertical"></i>
                              <i v-else class="fa-solid fa-xmark"></i>
                          </button>
                      </div>
                      <div v-if="showBoxId === document._id" class="absolute right-[0px] bottom-[-40px] h-[40px] flex justify-center items-center">
                        <div @click="toggleActive(document._id)" class="w-[30px] h-[30px] cursor-pointer mx-[5px] shadow-4xl rounded-[50px] flex justify-center items-center text-sm font-bold" :class="{'bg-white text-green-600': !document.isActive, 'bg-green-600 text-white': document.isActive}"><i class="fa-solid fa-check"></i></div>
                          <div @click="modificationdocument(document)" class="w-[30px] h-[30px] cursor-pointer mx-[5px] shadow-4xl rounded-[50px] flex justify-center items-center bg-white text-blue-600 text-sm font-bold"><i class="fa-solid fa-pen"></i></div>
                          <div @click="deletedocument(document._id)" class="w-[30px] h-[30px] cursor-pointer mx-[5px] shadow-4xl rounded-[50px] flex justify-center items-center bg-white text-red-600 text-sm font-bold"><i class="fa-solid fa-trash"></i></div>
                      </div>
                  </div>
                  <div class="md:py-[50px] py-[25px] flex w-full md:justify-end justify-center items-center px-[20px]">
                      <div v-for="page in totalPages" :key="page" @click="goToPage(page)" 
                      :class="{'bg-blue-950 text-white border border-blue-950': currentPage === page, 'bg-white': currentPage !== page}" 
                      class="w-[30px] h-[30px] mx-[5px] rounded-[5px] shadow-4xl cursor-pointer border flex justify-center items-center font-bold text-sm text-center">
                          {{ page }}
                      </div>
                  </div>
              </div>
          </div>
          <div v-if="!isLoading && paginatedDocument.length === 0" class="w-full h-[200px] flex justify-center items-center text-center text-gray-600">
              Aucun document trouvé.
          </div>
          <div v-if="isLoading" class="w-full h-[200px] flex justify-center items-center">
              <div class="spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
              </div>
          </div>
          <div v-if="isAjouter" class="md:w-[calc(100%-250px)] w-full md:left-[250px] z-50 left-0 top-0 h-full bg-black/40 fixed">
              <div class="absolute md:top-[50%] top-[55%] translate-y-[-50%] left-[50%] parent overflow-y-auto p-[10px] translate-x-[-50%] max-h-[80%] h-auto w-[90%] max-w-[400px] bg-white rounded-[10px]">
                  <div class="w-full sticky top-0 bg-white z-30 py-[10px] text-sm font-bold text-center text-gray-800">
                      <span v-if="!modification">Ajouter un document</span>
                      <span v-if="modification">Modifier le document</span>
                  </div>
                  <form @submit.prevent="submitForm()" class="w-full flex py-[10px] relative flex-col">
                      <div class="my-[10px] w-full relative">
                          <input type="text" required v-model="titre" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Titre">
                      </div>
                      <div class="my-[10px] w-full relative">
                          <input type="text" required v-model="matiere" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Matière">
                      </div>
                      <div class="w-full relative md:flex justify-between items-center">
                          <!-- Select for Filière -->
                          <div class="md:w-[48%] w-full relative md:mt-0 mt-[15px]">
                            <select required v-model="selectedFiliere" @change="filterAnnees" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500">
                              <option value="" disabled selected hidden>Filière</option>
                              <option v-for="filiere in filieres" :key="filiere.nom" :value="filiere.nom">
                                {{ filiere.nom }}
                              </option>
                            </select>
                          </div>
                        
                          <!-- Select for Année Scolaire -->
                          <div class="md:w-[48%] w-full relative md:mt-0 mt-[15px]">
                            <select required v-model="selectedAnnee" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500">
                              <option value="" disabled selected hidden>Année Scolaire</option>
                              <option v-for="annee in filteredAnnees" :key="annee" :value="annee">
                                {{ annee }}
                              </option>
                            </select>
                          </div>
                      </div>
                      <div class="my-[10px] w-full relative">
                          <input type="date" v-model="recommandationDate" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Date de recommandation">
                      </div>
                      <div class="my-[10px] w-full flex items-center relative">
                          <input type="checkbox" v-model="isRecommande" class="p-[10px] mt-[2.5px]" >
                          <label for="" class="text-sm ml-[10px] text-gray-800 font-semibold">Recommander ce document</label>
                      </div>
                      <div class="my-[10px] w-full relative">
                          <input type="file" :required="!modification" @change="handleFileUpload" accept="application/pdf" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="PDF">
                      </div>
                      <div class="my-[10px] w-full relative">
                          <button v-if="!modification" class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                              <span v-if="!isLoading">Ajouter</span>
                              <div v-if="isLoading" class="spinner1">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                              </div>
                          </button>
                          <button v-if="modification" class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                              <span v-if="!isLoading">Modifier</span>
                              <div v-if="isLoading" class="spinner1">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                              </div>
                          </button>
                      </div>
                  </form>
              </div>
              <div @click="toggleAjout()" class="w-[30px] h-[30px] absolute bottom-[0px] cursor-pointer left-[50%] translate-x-[-50%] rounded-[50%] bg-gray-300 flex justify-center items-center">
                  <i class="fa-solid fa-times"></i>
              </div>
          </div>
      </section>
  </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { ref, computed, onMounted, watch } from 'vue'
import axios from "axios"

const isAjouter = ref(false)
const modification = ref(false)
function toggleAjout() {
  isAjouter.value = !isAjouter.value
  modification.value = false
}
const documents = ref([])
const errorMessage = ref('')
const successMessage = ref('')
const titre = ref('')
const matiere = ref('')
const filiere = ref('')
const year = ref('')
const recommandationDate = ref('')
const isRecommande = ref(false)
const content = ref(null)
const isLoading = ref('')
const troisPoints = ref(false)
const showBoxId = ref(null);
const documentId = ref('')
const searchQuery = ref('')
/* 
function toggleCorrection() {
  isCorection.value = !isCorection.value
} */
function toggleBox(id) {
showBoxId.value = showBoxId.value === id ? null : id;
}
function toggleCorrection(id) {
isCorection.value = isCorection.value === id ? null : id;
}
function modificationdocument(document) {
  isAjouter.value = true,
  modification.value = true,
  titre.value = document.titre,
  selectedFiliere.value = document.filiere,
  matiere.value = document.matiere,
  selectedAnnee.value = document.year,
  documentId.value = document._id
  content.value = document.content
  recommandationDate.value = document.recommandationDate
  isRecommande.value = document.isRecommande
}

const fetchdocuments = async() => {
  isLoading.value = true
  try {
      const response = await axios.get('/api/documents/all')
      documents.value = response.data
  } catch {
      errorMessage.value = "Erreur lors du chargement des documents"
      hideErrorMessageAfterDelay()
  } finally {
      isLoading.value = false
  }
}

const filteredExercices = computed(() => {
    if (!searchQuery.value) return documents.value;

    return documents.value.filter(document => {
        return (
            document.titre.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            document.matiere.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            document.filiere.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            document.year.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
    });
});


const handleFileUpload = (event) => {
  content.value = event.target.files[0];
}
const handleFileUpload1 = (event) => {
  contentCorrige.value = event.target.files[0];
}

const submitdocument = async () => {
  isLoading.value = true;
  try {
      const formData = new FormData();
      formData.append('titre', titre.value);
      formData.append('matiere', matiere.value);
      formData.append('filiere', selectedFiliere.value);
      formData.append('year', selectedAnnee.value);
      formData.append('recommandationDate', recommandationDate.value);
      formData.append('isRecommande', isRecommande.value);

      if (content.value) {
          formData.append('content', content.value); 
      }

      const response = await axios.post('/api/documents/add', formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      });

      if (response.status === 200 || response.status === 201) {
          successMessage.value = "Document ajouté avec succès";
          hideSuccessMessageAfterDelay();
          fetchdocuments();
          isAjouter.value = false;
      } else {
          errorMessage.value = "Erreur inattendue lors de l'ajout du document";
          hideErrorMessageAfterDelay();
      }

      // Réinitialisation des valeurs après succès
      titre.value = '';
      matiere.value = '';
      selectedFiliere.value = '';
      selectedAnnee.value = '';
      content.value = null;
      recommandationDate.value = "";
      isRecommande.value = false;
  } catch (error) {
      errorMessage.value = "Erreur lors de l'ajout du document";
      hideErrorMessageAfterDelay();
  } finally {
      isLoading.value = false;
  }
};


const submitModificationdocument = async () => {
  isLoading.value = true
  try {
      const formData = new FormData();
      formData.append('titre', titre.value);
      formData.append('matiere', matiere.value);
      formData.append('filiere', selectedFiliere.value);
      formData.append('year', selectedAnnee.value);
      formData.append('recommandationDate', recommandationDate.value);
      formData.append('isRecommande', isRecommande.value);

      if (content.value) {
          formData.append('content', content.value);
      }
      const response = await axios.put(`/api/documents/update/${documentId.value}`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      });

      if (response.status === 200 || response.status === 201) {
          successMessage.value = "Document modifié avec succès";
          hideSuccessMessageAfterDelay()
          fetchdocuments();
          isAjouter.value = false;
          modification.value = false;
      } else {
          errorMessage.value = "Erreur inattendue lors de la modification du document";
          hideErrorMessageAfterDelay()
      }
      titre.value = ''
      matiere.value = ''
      selectedFiliere.value = ''
      selectedAnnee.value = ''
      content.value = null
      recommandationDate.value = "";
      isRecommande.value = false;
  } catch (error) {
      errorMessage.value = "Erreur lors de la modification du document";
      hideErrorMessageAfterDelay()
  } finally {
      isLoading.value = false
  }
};

const submitForm = async () => {
 if (modification.value) {
     await submitModificationdocument();
 } else {
     await submitdocument();
 }
};

const currentPage = ref(1);
const itemsPerPage = ref(20);
const totalPages = computed(() => Math.ceil(filteredExercices.value.length / itemsPerPage.value));

const paginatedDocument = computed(() => {
const start = (currentPage.value - 1) * itemsPerPage.value;
const end = start + itemsPerPage.value;
return filteredExercices.value.slice(start, end);
});

const goToPage = (pageNumber) => {
if (pageNumber >= 1 && pageNumber <= totalPages.value) {
  currentPage.value = pageNumber;
}
};

const deletedocument = async (id) => {
try {
  await axios.delete(`/api/documents/delete/${id}`);
  fetchdocuments()
} catch (error) {
  errorMessage.value = 'Erreur lors de la suppression du document.';
  hideErrorMessageAfterDelay()
}
};

const hideSuccessMessageAfterDelay = () => {
  setTimeout(() => {
    successMessage.value = ''; 
  }, 3000);
};

const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 3000);
};

onMounted(() => {
  fetchdocuments()
})

const toggleActive = async (id) => {
  try {
    await axios.put(`/api/documents/${id}/toggle-active/`);
    const index = documents.value.findIndex((e) => e._id === id);
    documents.value[index].isActive = !documents.value[index].isActive;
    fetchdocuments()
  } catch (error) {
    errorMessage.value = 'Erreur lors du changement de statut.';
    hideErrorMessageAfterDelay()
  }
};

const selectedFiliere = ref('');
const selectedAnnee = ref('');
const filteredAnnees = ref([]);
const filieres = ref([])

const fetchFilieres = async () => {
  try {
    const response = await axios.get(`/api/filieres`);
    filieres.value = response.data;
  } catch (err) {
    console.error('Erreur lors du chargement des filières:', err);
  }
};

const filterAnnees = () => {
  if (selectedFiliere.value) {
    const filiere = filieres.value.find(filiere => filiere.nom === selectedFiliere.value);
    if (filiere) {
      filteredAnnees.value = filiere.annee; // Assure-toi que `annee` est bien un tableau dans le modèle
    } else {
      filteredAnnees.value = [];
    }
  } else {
    filteredAnnees.value = [];
  }
};

onMounted(() => {
    fetchFilieres();
})

watch(selectedFiliere, () => {
  filterAnnees();
});

</script>

<style>
</style>