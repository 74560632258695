<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] md:left-[60%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 w-full h-screen overflow-y-auto md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0">
            <div class="w-full relative flex md:justify-end mt-[20px] md:mt-0 justify-center items-center">
                <button @click="toggleAjout()" class="p-[10px] text-center bg-black rounded-[5px] flex items-center justify-center text-gray-200 font-bold text-sm w-full md:w-auto">
                    Ajouter une épreuve
                </button>
            </div>
            <div class="w-full relative my-[10px]">
                <input type="text" v-model="searchQuery" class="p-[10px] relative w-full text-sm border bg-transparent outline-none" placeholder="Rechercher par titre, matière, filière ou année"/>
            </div>
            <div class="w-full flex md:mt-[50px] mt-[20px] justify-between items-start flex-wrap relative">
                <div class="w-full">
                    <div v-for="(epreuve, index) in paginatedClients" :key="index" :class="{'mb-[50px]': showBoxId === epreuve._id}" class="w-full relative md:px-[20px] px-[10px] cursor-pointer my-[5px] py-[10px] rounded-[5px] bg-white flex justify-between items-center">
                        <div class="flex items-center md:w-auto w-[calc(100%-80px)]">
                            <div class="w-[35px] h-[40px] min-w-[35px] min-h-[40px] relative overflow-hidden flex justify-center items-center">
                                <img src="../assets/images/epreuve.jpeg" alt="" class="w-full h-full absolute object-contain">
                            </div>
                            <router-link class="flex flex-col ml-[10px]" :to="'/epreuves-corriges/'+ epreuve._id">
                                <h1 class="text-sm clamp font-bold text-gray-800">{{ epreuve.matiere }}</h1>
                                <p class="text-[10px] clamp font-semibold hover:underline text-gray-500">
                                    {{ epreuve.titre }}
                                </p>
                            </router-link>
                        </div>
                        <div class="flex items-center">
                            <div class="p-[10px] rounded-[5px] bg-gray-100 flex text-[10px] font-bold text-gray-600">
                                <div class="hidden md:flex items-center">
                                    <i class="fa-solid fa-calendar pr-[5px]"></i>
                                    <span>{{ epreuve.createdAt }}</span>
                                </div>
                                <span class="mx-[5px] hidden md:flex">|</span>
                                <div class="flex items-center">
                                    <i class="fa-solid fa-star pr-[5px]"></i>
                                    <span>5</span>
                                </div>
                            </div>
                            <button @click="modificationEpreuve(epreuve)" v-if="epreuve.correction" class="p-[5px] flex items-center rounded-[5px] font-bold ml-[10px] border border-gray-800 text-sm text-gray-800">
                                <span class="hidden md:flex">Modifier le corrigé</span>
                                <i class="fa-solid fa-pen md:hidden md:ml-[5px] text-sm"></i>
                            </button>
                            <button @click="toggleCorrection(epreuve._id)" v-if="!epreuve.correction" class="p-[5px] flex items-center rounded-[5px] font-bold ml-[10px] border border-gray-800 text-sm text-gray-800">
                                <span class="hidden md:flex">Ajouter le corrigé</span>
                                <i class="fa-solid fa-add md:hidden md:ml-[5px] text-sm"></i>
                            </button>
                            <button @click="toggleBox(epreuve._id)" class="ml-[10px] w-[20px]">
                                <i v-if="showBoxId !== epreuve._id" class="fa-solid fa-ellipsis-vertical"></i>
                                <i v-else class="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <div v-if="showBoxId === epreuve._id" class="absolute right-[0px] bottom-[-40px] h-[40px] flex justify-center items-center">
                            <div @click="toggleActive(epreuve._id)" class="w-[30px] h-[30px] cursor-pointer mx-[5px] shadow-4xl rounded-[50px] flex justify-center items-center text-sm font-bold" :class="{'bg-white text-green-600': !epreuve.isActive, 'bg-green-600 text-white': epreuve.isActive}"><i class="fa-solid fa-check"></i></div>
                            <div @click="modificationEpreuve(epreuve)" class="w-[30px] h-[30px] cursor-pointer mx-[5px] shadow-4xl rounded-[50px] flex justify-center items-center bg-white text-blue-600 text-sm font-bold"><i class="fa-solid fa-pen"></i></div>
                            <div @click="deleteEpreuve(epreuve._id)" class="w-[30px] h-[30px] cursor-pointer mx-[5px] shadow-4xl rounded-[50px] flex justify-center items-center bg-white text-red-600 text-sm font-bold"><i class="fa-solid fa-trash"></i></div>
                        </div>
                        <div v-if="isCorection === epreuve._id" class="md:w-[calc(100%-250px)] w-full md:left-[250px] z-50 left-0 top-0 h-full bg-black/40 fixed">
                            <div class="absolute md:top-[50%] top-[55%] translate-y-[-50%] left-[50%] p-[10px] translate-x-[-50%] max-h-[80%] h-auto w-[90%] max-w-[400px] bg-white rounded-[10px]">
                                <div class="w-full sticky top-0 py-[10px] text-sm font-bold text-center text-gray-800">
                                    Ajouter une correction
                                </div>
                                <form @submit.prevent="submitCorrection(epreuve._id)" class="w-full flex py-[10px] relative flex-col">
                                    <div class="my-[10px] w-full relative">
                                        <input type="text" required v-model="titre" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Titre">
                                    </div>
                                    <div class="my-[10px] w-full relative">
                                        <input type="file" @change="handleFileUpload" accept="application/pdf" required class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="PDF">
                                    </div>
                                    <div class="my-[10px] w-full relative">
                                        <button type="submit" class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                                            <span v-if="!isLoading">Ajouter</span>
                                            <div v-if="isLoading" class="spinner1">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </button>
                                    </div>
                                </form>
                                <div @click="toggleCorrection(epreuve._id)" class="w-[30px] h-[30px] absolute bottom-[-40px] cursor-pointer left-[50%] translate-x-[-50%] rounded-[50%] bg-gray-300 flex justify-center items-center">
                                    <i class="fa-solid fa-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="md:py-[50px] py-[25px] flex w-full md:justify-end justify-center items-center px-[20px]">
                        <div v-for="page in totalPages" :key="page" @click="goToPage(page)" 
                        :class="{'bg-blue-950 text-white border border-blue-950': currentPage === page, 'bg-white': currentPage !== page}" 
                        class="w-[30px] h-[30px] mx-[5px] rounded-[5px] shadow-4xl cursor-pointer border flex justify-center items-center font-bold text-sm text-center">
                            {{ page }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isLoading && paginatedClients.length === 0" class="w-full h-[200px] flex justify-center items-center text-center text-gray-600">
                Aucune épreuve trouvée.
            </div>
            <div v-if="isLoading" class="w-full h-[200px] flex justify-center items-center">
                <div class="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div v-if="isAjouter" class="md:w-[calc(100%-250px)] w-full md:left-[250px] z-50 left-0 top-0 h-full bg-black/40 fixed">
                <div class="absolute md:top-[50%] top-[55%] translate-y-[-50%] left-[50%] parent overflow-y-auto p-[10px] translate-x-[-50%] max-h-[80%] h-auto w-[90%] max-w-[400px] bg-white rounded-[10px]">
                    <div class="w-full sticky top-0 bg-white z-30 py-[10px] text-sm font-bold text-center text-gray-800">
                        <span v-if="!modification">Ajouter une épreuve</span>
                        <span v-if="modification">Modifier l'épreuve</span>
                    </div>
                    <form @submit.prevent="submitForm()" class="w-full flex py-[10px] relative flex-col">
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="titre" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Titre">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="matiere" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Matière">
                        </div>
                        <div class="w-full relative md:flex justify-between items-center">
                          <!-- Select for Filière -->
                          <div class="md:w-[48%] w-full relative md:mt-0 mt-[15px]">
                            <select required v-model="selectedFiliere" @change="filterAnnees" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500">
                              <option value="" disabled selected hidden>Filière</option>
                              <option v-for="filiere in filieres" :key="filiere.nom" :value="filiere.nom">
                                {{ filiere.nom }}
                              </option>
                            </select>
                          </div>
                        
                          <!-- Select for Année Scolaire -->
                          <div class="md:w-[48%] w-full relative md:mt-0 mt-[15px]">
                            <select required v-model="selectedAnnee" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500">
                              <option value="" disabled selected hidden>Année Scolaire</option>
                              <option v-for="annee in filteredAnnees" :key="annee" :value="annee">
                                {{ annee }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="file" @change="handleFileUpload" accept="application/pdf" :required="!modification" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="PDF">
                        </div>
                        <div v-if="modification" class="my-[10px] w-full relative">
                            <input type="text"  v-model="titreCorrige" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Titre">
                        </div>
                        <div v-if="modification" class="my-[10px] w-full relative">
                            <input type="file" @change="handleFileUpload1" accept="application/pdf" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="PDF">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <button v-if="!modification" class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                                <span v-if="!isLoading">Ajouter</span>
                                <div v-if="isLoading" class="spinner1">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </button>
                            <button v-if="modification" class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                                <span v-if="!isLoading">Modifier</span>
                                <div v-if="isLoading" class="spinner1">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
                <div @click="toggleAjout()" class="w-[30px] h-[30px] absolute bottom-[0px] cursor-pointer left-[50%] translate-x-[-50%] rounded-[50%] bg-gray-300 flex justify-center items-center">
                    <i class="fa-solid fa-times"></i>
                </div>
            </div>
        </section>
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { ref, computed, onMounted, watch } from 'vue'
import axios from "axios"

const isAjouter = ref(false)
const modification = ref(false)
function toggleAjout() {
    isAjouter.value = !isAjouter.value
    modification.value = false
}
const epreuves = ref([])
const errorMessage = ref('')
const successMessage = ref('')
const titre = ref('')
const matiere = ref('')
const filiere = ref('')
const year = ref('')
const content = ref(null)
const isLoading = ref('')
const troisPoints = ref(false)
const showBoxId = ref(null);
const isCorection = ref(null)
const titreCorrige = ref('')
const contentCorrige = ref(null)
const epreuveId = ref('')
const searchQuery = ref('')
/* 
function toggleCorrection() {
    isCorection.value = !isCorection.value
} */
function toggleBox(id) {
  showBoxId.value = showBoxId.value === id ? null : id;
}
function toggleCorrection(id) {
  isCorection.value = isCorection.value === id ? null : id;
}
function modificationEpreuve(epreuve) {
    isAjouter.value = true,
    modification.value = true,
    titre.value = epreuve.titre,
    selectedFiliere.value = epreuve.filiere,
    matiere.value = epreuve.matiere,
    selectedAnnee.value = epreuve.year,
    epreuveId.value = epreuve._id
    content.value = epreuve.content
    if ( epreuve.correction ) {
        titreCorrige.value = epreuve.correction.titre
        contentCorrige.value = epreuve.correction.content
    }
}

const fetchEpreuves = async() => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/epreuves')
        epreuves.value = response.data
    } catch {
        errorMessage.value = "Erreur lors du chargement des membres"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const filteredExercices = computed(() => {
    if (!searchQuery.value) return epreuves.value;

    return epreuves.value.filter(epreuve => {
        return (
            epreuve.titre.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            epreuve.matiere.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            epreuve.filiere.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            epreuve.year.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
    });
});


const handleFileUpload = (event) => {
    content.value = event.target.files[0];
}
const handleFileUpload1 = (event) => {
    contentCorrige.value = event.target.files[0];
}

const submitEpreuve = async () => {
    isLoading.value = true
    try {
        const response = await axios.post('/api/epreuves', {
            titre: titre.value,
            matiere: matiere.value,
            filiere: selectedFiliere.value,
            year: selectedAnnee.value,
            content: content.value
        }, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.status === 200 || response.status === 201) {
            successMessage.value = "Epreuve ajoutée avec succès";
            hideSuccessMessageAfterDelay()
            fetchEpreuves();
            isAjouter.value = false;
        } else {
            errorMessage.value = "Erreur inattendue lors de l'ajout de l'épreuve";
            hideErrorMessageAfterDelay()
        }
        titre.value = ''
        matiere.value = ''
        selectedFiliere.value = ''
        selectedAnnee.value = ''
        content.value = null
    } catch (error) {
        errorMessage.value = "Erreur lors de l'ajout de l'épreuve";
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
};
const submitModificationEpreuve = async () => {
    isLoading.value = true
    try {
        const formData = new FormData();
        formData.append('titre', titre.value); // Assurez-vous que les valeurs ne sont pas vides
        formData.append('matiere', matiere.value);
        formData.append('filiere', selectedFiliere.value);
        formData.append('year', selectedAnnee.value);

        // Ajouter le fichier de l'épreuve seulement s'il a été modifié
        if (content.value) {
            formData.append('content', content.value); // Assurez-vous que content.value est de type File
        }

        // Ajouter les données de correction si elles existent
        if (titreCorrige.value) formData.append('correctionTitre', titreCorrige.value);
        if (contentCorrige.value) {
            formData.append('correctionContent', contentCorrige.value); // Assurez-vous que contentCorrige.value est de type File
        }

        const response = await axios.put(`/api/epreuves/${epreuveId.value}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200 || response.status === 201) {
            successMessage.value = "Epreuve modifié avec succès";
            hideSuccessMessageAfterDelay()
            fetchEpreuves();
            isAjouter.value = false;
            modification.value = false;
        } else {
            errorMessage.value = "Erreur inattendue lors de la modification de l'épreuve";
            hideErrorMessageAfterDelay()
        }
        titre.value = ''
        matiere.value = ''
        selectedFiliere.value = ''
        selectedAnnee.value = ''
        content.value = null
    } catch (error) {
        errorMessage.value = "Erreur lors de la modification de l'épreuve";
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
};

const submitForm = async () => {
   if (modification.value) {
       await submitModificationEpreuve();
   } else {
       await submitEpreuve();
   }
};

const currentPage = ref(1);
const itemsPerPage = ref(20);
const totalPages = computed(() => Math.ceil(filteredExercices.value.length / itemsPerPage.value));

const paginatedClients = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return filteredExercices.value.slice(start, end);
});

const goToPage = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= totalPages.value) {
    currentPage.value = pageNumber;
  }
};

const deleteEpreuve = async (id) => {
  try {
    await axios.delete(`/api/epreuves/${id}`);
    fetchEpreuves()
  } catch (error) {
    errorMessage.value = 'Erreur lors de la suppression de l\'épreuve.';
    hideErrorMessageAfterDelay()
  }
};

const submitCorrection = async (id) => {
    try {
        const response = await axios.post(`/api/epreuves/${id}/correction`, {
            titre: titre.value,
            correctionContent: content.value
        }, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.status === 200 || response.status === 201) {
            successMessage.value = "Corrigé ajouté avec succès";
            hideSuccessMessageAfterDelay()
            fetchEpreuves();
            isCorection.value = null;
        } else {
            errorMessage.value = "Erreur inattendue lors de l'ajout du corrigé";
            hideErrorMessageAfterDelay()
        }
        titre.value = ''
        content.value = null
    } catch {
        errorMessage.value = 'Erreur lors de l\'ajout du corrigé';
        hideErrorMessageAfterDelay()
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    fetchEpreuves()
})

const toggleActive = async (id) => {
  try {
    await axios.put(`/api/epreuves/${id}/toggle-active/`);
    const index = epreuves.value.findIndex((e) => e._id === id);
    epreuves.value[index].isActive = !epreuves.value[index].isActive;
    fetchEpreuves()
  } catch (error) {
    errorMessage.value = 'Erreur lors du changement de statut.';
    hideErrorMessageAfterDelay()
  }
};

const selectedFiliere = ref('');
const selectedAnnee = ref('');
const filteredAnnees = ref([]);
const filieres = ref([])

const fetchFilieres = async () => {
  try {
    const response = await axios.get(`/api/filieres`);
    filieres.value = response.data;
  } catch (err) {
    console.error('Erreur lors du chargement des filières:', err);
  }
};

const filterAnnees = () => {
  if (selectedFiliere.value) {
    const filiere = filieres.value.find(filiere => filiere.nom === selectedFiliere.value);
    if (filiere) {
      filteredAnnees.value = filiere.annee; // Assure-toi que `annee` est bien un tableau dans le modèle
    } else {
      filteredAnnees.value = [];
    }
  } else {
    filteredAnnees.value = [];
  }
};

onMounted(() => {
    fetchFilieres();
})

watch(selectedFiliere, () => {
  filterAnnees();
});

</script>

<style>
</style>