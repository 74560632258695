<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] md:left-[60%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 parent w-full h-screen md:rounded-l-[25px] relative md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="w-full relative flex justify-center items-center flex-col">
                <div class="mb-[25px] w-full relative flex flex-col">
                    <div class="w-full relative flex my-[10px] justify-between items-center">
                        <div class="flex flex-col">
                            <h1 class="text-sm clamp font-bold text-gray-800">{{ epreuve.matiere }}</h1>
                            <p class="text-[10px] clamp font-semibold text-gray-500">
                                {{ epreuve.titre }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full parent relative flex justify-center items-center flex-col">
                <div class="w-full flex justify-center items-center relative mt-[10px]">
                    <div id="pdf-container" @contextmenu.prevent></div>
                </div>
            </div>
            <div class="w-full relative flex justify-center items-center flex-col">
                <div class="mb-[25px] w-full relative flex flex-col">
                    <div class="w-full relative flex my-[10px] justify-between items-center">
                        <div class="flex flex-col">
                            <p class="text-sm clamp font-bold text-gray-800">
                                Correction de l'epreuve
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full parent relative flex justify-center items-center flex-col">
                <div class="w-full flex justify-center items-center relative mt-[10px]">
                    <div id="pdf-container-corrige" @contextmenu.prevent></div>
                </div>
            </div>
            <div class="w-full my-[50px] relative flex flex-col justify-center items-center">
                <div class="w-full relative my-[10px]">
                    <h1 class="w-full relative py-[10px] border-b border-gray-200 flex  justify-between items-center">
                        <span class="text-sm font-bold text-gray-500">{{ epreuve.correction ? epreuve.correction.commentaire.length : 0 }} commentaires</span>
                        
                    </h1>
                </div>
                <div v-if="epreuve.correction && epreuve.correction.commentaire && epreuve.correction.commentaire.length > 0" class="flex flex-col w-full relative items-center justify-center">
                    <div v-for="(commentaire, index) in epreuve.correction.commentaire" :key="index" class="w-full text-sm text-gray-700">
                        <div class="w-full my-[10px] bg-gray-200 rounded-[5px] p-[10px]">
                            <h1 v-if="commentaire && commentaire.content">
                                {{ commentaire.content }}
                            </h1>
                            <div class="flex items-center">
                                <p class="mt-[5px] text-xs font-bold text-gray-500">
                                    {{ commentaire.nom }}
                                </p>
                                <button @click="toggleReponse(commentaire._id)" class="mt-[5px] hover:underline ml-[10px] text-xs font-bold text-blue-500">
                                    Répondre
                                </button>
                            </div>
                            <form v-if="showReponse === commentaire._id" @submit.prevent="submitReponse(commentaire._id)" class="w-full relative mt-[20px]">
                                <div class="my-[10px] w-full relative flex items-start justify-center">
                                    <div class="w-[30px] md:w-[50px] md:mr-[20px] mr-[15px] h-[30px] md:h-[50px] min-w-[30px] md:min-w-[50px] min-h-[30px] md:min-h-[50px] flex justify-center items-center relative overflow-hidden rounded-[50%]">
                                        <img src="../assets/images/icone.png" alt="" class="w-full h-full absolute object-cover">
                                    </div>
                                    <div class="w-full flex flex-col justify-center items-center relative">
                                        <div class="w-full mb-[15px] relative">
                                            <textarea type="text" v-model="content" required placeholder="Entrez votre réponse" class="w-full p-[10px] h-[120px] resize-none border border-gray-400 outline-none text-sm rounded-[5px] bg-transparent"></textarea>
                                        </div>
                                        <div class="w-full relative">
                                            <button :disabled="isLoading" type="submit" class="p-[10px] w-[100px] h-[45px] flex justify-center items-center text-center bg-gray-800 font-bold text-white text-sm rounded-[5px]">
                                                <span v-if="!isLoading">Répondre</span>
                                                <div v-if="isLoading" class="spinner1">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div v-if="commentaire.reponse && commentaire.reponse.length > 0">
                            <div v-for="(reponse, idx) in commentaire.reponse" :key="idx" class="w-[calc(100%-25px)] ml-[25px] my-[10px] bg-green-200 rounded-[5px] p-[10px] text-sm text-gray-700">
                                <h1>{{ reponse.content }}</h1>
                                <p class="mt-[5px] text-xs font-bold text-green-600">
                                    {{ reponse.nom }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="w-full my-[10px] text-sm text-gray-700">
                    Il n'y a pas de commentaires pour cette correction.
                </div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { useAuthStore } from '@/store/auth';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const router = useRouter()
const epreuveId = router.currentRoute.value.params.id;
const epreuve = ref({})
const errorMessage = ref('')
const successMessage = ref('')
const epreuveContent = ref('')
const content = ref('')
const nom = user.value.firstname + ' ' + user.value.lastname
const userId = user.value._id
const isLoading = ref(false)
const liked = ref(false);
const likesCount = ref(0);
const showReponse = ref(null)

function toggleReponse(id) {
    showReponse.value = showReponse.value === id ? null : id;
}

const fetchepreuveDetail = async() => {
    try {
        const response = await axios.get(`/api/epreuves/${epreuveId}`)
        epreuve.value = response.data
        displayPDF();
        displayPDFCorrige()
    } catch (error) {
        console.log('Erreur lors du chargement de l\'epreuve')
    }
}

const submitReponse = async(id) => {
    isLoading.value = true
    try {
        const response = await axios.post(`/api/epreuves/${epreuveId}/commentaire/${id}/reponse`, {
            nom: user.value.username,
            content: content.value 
        })
        if ( response.status == 200 || response.status == 201 ) {
            successMessage.value = "Réponse envoyée avec succès"
            hideSuccessMessageAfterDelay()
            fetchepreuveDetail()
        } else {
            errorMessage.value = "Erreur lors de l'envoie de la réponse"
            hideErrorMessageAfterDelay()
        }
        showReponse.value = null
    } catch (error) {
        errorMessage.value = "Erreur lors de l'envoie de la réponse"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    fetchepreuveDetail()
})

const displayPDF = () => {
    const container = document.getElementById('pdf-container');
    container.innerHTML = ''; // Vide le conteneur avant de charger les pages

    const correctedPdfUrl = `https://api.revixo-learn.online/${epreuve.value.content.replace(/\\/g, '/')}`;

    pdfjsLib.getDocument(correctedPdfUrl).promise.then(pdf => {
        const containerWidth = container.clientWidth;
        const pixelRatio = window.devicePixelRatio || 1; // Récupère la densité de pixels de l'écran

        // Affiche toutes les pages
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            pdf.getPage(pageNumber).then(page => {
                // Calculer l'échelle pour s'adapter à la largeur du conteneur
                const viewport = page.getViewport({ scale: 1 });
                const scale = containerWidth / viewport.width;
                const scaledViewport = page.getViewport({ scale: scale });

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                
                // Ajuster la résolution pour une meilleure netteté
                canvas.height = scaledViewport.height * pixelRatio;
                canvas.width = scaledViewport.width * pixelRatio;
                canvas.style.height = `${scaledViewport.height}px`;
                canvas.style.width = `${scaledViewport.width}px`;

                // Désactive le clic droit et la sélection de texte
                canvas.style.pointerEvents = 'none';
                canvas.style.userSelect = 'none';

                // Rendre la page sur le canvas en tenant compte du ratio de pixels
                context.scale(pixelRatio, pixelRatio);
                page.render({ canvasContext: context, viewport: scaledViewport }).promise.then(() => {
                    container.appendChild(canvas);
                });
            });
        }
    }).catch(error => {
        errorMessage.value = 'Erreur lors de l\'affichage du PDF';
        hideErrorMessageAfterDelay();
    });
};

const displayPDFCorrige = () => {
    const container = document.getElementById('pdf-container-corrige');
    container.innerHTML = ''; // Vide le conteneur avant de charger les pages

    const correctedPdfUrl = `https://api.revixo-learn.online/${epreuve.value.correction.content.replace(/\\/g, '/')}`;

    pdfjsLib.getDocument(correctedPdfUrl).promise.then(pdf => {
        const containerWidth = container.clientWidth;
        const pixelRatio = window.devicePixelRatio || 1; // Récupère la densité de pixels de l'écran

        // Affiche toutes les pages
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            pdf.getPage(pageNumber).then(page => {
                // Calculer l'échelle pour s'adapter à la largeur du conteneur
                const viewport = page.getViewport({ scale: 1 });
                const scale = containerWidth / viewport.width;
                const scaledViewport = page.getViewport({ scale: scale });

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                
                // Ajuster la résolution pour une meilleure netteté
                canvas.height = scaledViewport.height * pixelRatio;
                canvas.width = scaledViewport.width * pixelRatio;
                canvas.style.height = `${scaledViewport.height}px`;  // Taille CSS
                canvas.style.width = `${scaledViewport.width}px`;    // Taille CSS

                // Désactive le clic droit et la sélection de texte
                canvas.style.pointerEvents = 'none';
                canvas.style.userSelect = 'none';

                // Rendre la page sur le canvas en tenant compte du ratio de pixels
                context.scale(pixelRatio, pixelRatio);
                page.render({ canvasContext: context, viewport: scaledViewport }).promise.then(() => {
                    container.appendChild(canvas);
                });
            });
        }
    }).catch(error => {
        errorMessage.value = 'Erreur lors de l\'affichage du PDF';
        hideErrorMessageAfterDelay();
    });
};
</script>

<style scoped>
#pdf-container-corrige {
    user-select: none;
    pointer-events: none;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
#pdf-container {
    user-select: none;
    pointer-events: none;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
canvas {
  pointer-events: none;
  width: 100%;
}
</style>