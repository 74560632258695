<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] md:left-[60%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 parent w-full h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="w-full relative flex lg:flex-row flex-col justify-center lg:h-[100%] h-auto rounded-[10px] shadow-4xl items-start">
                <div class="lg:w-[30%] w-full bg-gray-50 lg:h-full h-auto relative">
                    <div class="w-full h-[180px] p-[20px] lg:flex hidden justify-center items-center relative">
                        <div class="w-full h-full relative rounded-[20px] shadow-4xl overflow-hidden flex justify-center items-center">
                            <img src="../assets/images/icone.png" alt="" class="w-full h-full absolute object-cover">
                            <div class="w-full h-full absolute top-0 bg-gray-800/60 flex flex-col justify-center items-center"></div>
                        </div>
                    </div>
                    <div class="w-full lg:mt-[10px] flex flex-row lg:flex-col">
                        <div @click="toggleInformation()" class="p-[10px] w-[calc(100%/3)] lg:w-full text-sm cursor-pointer font-bold duration-200 text-gray-600 flex flex-col md:flex-row justify-center lg:justify-start items-center" :class="{'lg:border-l-[5px] border-t-[5px] lg:border-t-0 border-blue-950': isInformation, 'lg:border-l-[5px] border-t-[5px] lg:border-t-0 border-gray-50': !isInformation}">
                            <i class="fa-solid fa-user w-[25px] h-[20px] lg:h-auto text-left flex justify-start"></i>
                            <h1>Information</h1>
                        </div>
                        <div @click="togglePassword()" class="p-[10px] w-[calc(100%/3)] lg:w-full text-sm cursor-pointer font-bold duration-200 text-gray-600 flex flex-col md:flex-row justify-center lg:justify-start items-center" :class="{'lg:border-l-[5px] border-t-[5px] lg:border-t-0 border-blue-950': isPassword, 'lg:border-l-[5px] border-t-[5px] lg:border-t-0 border-gray-50': !isPassword}">
                            <i class="fa-solid fa-lock w-[25px] h-[20px] lg:h-auto text-left flex justify-start"></i>
                            <h1>Mot de passe</h1>
                        </div>
                    </div>
                </div>
                <div class="lg:w-[70%] w-full bg-white p-[20px] h-full flex flex-col relative overflow-y-auto parent">
                    <h1 v-if="isInformation" class="text-sm md:text-2xl font-bold text-gray-800">Informations personnelles</h1>
                    <form v-if="isInformation" @submit.prevent="submitForm()" class="w-full flex py-[10px] relative flex-col">
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="nom" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Nom">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="prenoms" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Prénoms">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="email" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Email">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="phone" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Numéro">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <button class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                                <span v-if="!isLoading">Modifier les Informations</span>
                                <div v-if="isLoading" class="spinner">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </button>
                        </div>
                    </form>
                    <h1 v-if="isPassword" class="text-sm md:text-2xl font-bold text-gray-800">Mot de passe</h1>
                    <form v-if="isPassword" @submit.prevent="submitFormPassword()" class="w-full flex py-[10px] relative flex-col">
                        <div class="my-[10px] w-full relative">
                            <input type="password" required v-model="Opassword" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Ancien mot de passe">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="password" required v-model="Npassword" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Nouveau mot de passe">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="password" required v-model="CNpassword" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Confirmer le nouveau mot de passe">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <button class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                                <span v-if="!isLoading">Modifier le mot de passe</span>
                                <div v-if="isLoading" class="spinner">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { ref, computed } from 'vue'
import { useAuthStore } from '@/store/auth';
import axios from 'axios'

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const isInformation = ref(true)
const isPassword = ref(false)
const isAbonnement = ref(false)
const isLoading = ref(false)

function toggleInformation() {
    isInformation.value = true
    isPassword.value = false
    isAbonnement.value = false
}
function togglePassword() {
    isInformation.value = false
    isPassword.value = true
    isAbonnement.value = false
}
function toggleAbonnement() {
    isInformation.value = false
    isPassword.value = false
    isAbonnement.value = true
}
// États pour stocker le jour, le mois et l'année
const day = ref('');
const month = ref('');
const year = ref('');

// Liste des jours de 1 à 31
const days = computed(() => Array.from({ length: 31 }, (_, i) => i + 1));

// Liste des mois
const months = ref([
  'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
  'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
]);

// Liste des années (de l'année actuelle à 100 ans en arrière)
const currentYear = new Date().getFullYear();
const years = computed(() => Array.from({ length: 100 }, (_, i) => currentYear - i));

// Optionnel: validation ou logique supplémentaire
const isDateValid = computed(() => {
  return day.value && month.value && year.value;
});

const errorMessage = ref('')
const successMessage = ref('')
const nom = ref('')
const prenoms = ref('')
const email = ref('')
const phone = ref('')
const Opassword = ref('')
const Npassword = ref('')
const CNpassword = ref('')

const submitForm = async() => {
    isLoading.value = true
    try {
        const response = await axios.put(`/api/users/${user.value._id}`, {
            firstname: prenoms.value,
            lastname: nom.value,
            email: email.value,
            phone: phone.value
        })
        if ( response.status == 200 || response.status == 201 ) {
            successMessage.value = "Vos informations ont bien été modifiées"
            hideSuccessMessageAfterDelay()
            authStore.setUser(response.data.user);
        } else {
            errorMessage.value = "Erreur inattendue lors de la modification des informations"
            hideErrorMessageAfterDelay()
        }
        prenoms.value = ''
        nom.value = ''
        email.value = ''
        phone.value = ''
    } catch {
        errorMessage.value = "Erreur lors de la modification des informations"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const submitFormPassword = async() => {
    isLoading.value = true
    if (Npassword.value !== CNpassword.value) {
        errorMessage.value = "Les nouveaux mots de passe ne correspondent pas!"
        hideErrorMessageAfterDelay()
        isLoading.value = false

        return
    }
    try {
        const response = await axios.post(`/api/users/reset-password`, {
            email: user.value.email,
            oldPassword: Opassword.value,
            newPassword: Npassword.value
        })
        if ( response.status == 200 || response.status == 201 ) {
            successMessage.value = "Votre mot de passe a été modifié avec succès"
            hideSuccessMessageAfterDelay()
        } else {
            errorMessage.value = "Erreur inattendue lors de la modification du mot de passe"
            hideErrorMessageAfterDelay()
        }
        Opassword.value = ''
        Npassword.value = ''
        CNpassword.value = ''
    } catch {
        errorMessage.value = "Erreur lors de la modification du mot de passe"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

</script>

<style scoped>
.birthday-fields {
  display: flex;
  gap: 1rem;
}
label {
  margin-right: 0.5rem;
}
</style>