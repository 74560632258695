<template>
    <div :class="{'translate-x-[-100%]': !isOpen, 'translate-x-0': isOpen}" class="w-[250px] md:translate-x-0 fixed md:static duration-300 top-0 left-0 bottom-0 z-50 min-w-[250px] p-[20px] h-screen max-h-screen bg-[#151036]">
        <div class="w-full my-[10px] mb-[50px]">
            <h1 class="text-3xl text-white uppercase roboto-bold">Revixo</h1>
            <p class="text-sm text-gray-200 roboto-light uppercase">Tout pour réviser !!</p>
        </div>
        <div class="w-full overflow-y-auto h-[calc(100vh-280px)] parent">
            <div @click="goToDashboardPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/dashboard', 'text-white degrade3': $route.path == '/dashboard'}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M3 13h8V3H3zm0 8h8v-6H3zm10 0h8V11h-8zm0-18v6h8V3z"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Dashboard</h1>
            </div>
            <div @click="goToEpreuvesPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/epreuves-corriges' || isEpreuveRoute, 'text-white degrade3': $route.path == '/epreuves-corriges' || isEpreuveRoute}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48"><g fill="none"><path d="M0 0h48v48H0z"/><path fill="currentColor" fill-rule="evenodd" d="M39 13a3 3 0 0 0-3 3v2h6v-2a3 3 0 0 0-3-3m3 7h-6v16.5l3 4.5l3-4.5zM6 9v30a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3m14 6a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2zm-1 10a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2zm-9-3v3h3v-3zm-1-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1m6.707-10.293a1 1 0 0 0-1.414-1.414L13 17.586l-1.293-1.293a1 1 0 0 0-1.414 1.414L13 20.414z" clip-rule="evenodd"/></g></svg>
                <h1 class="text-sm font-bold ml-[10px]">Epreuves</h1>
            </div>
            <div @click="goToCorrigesPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative hidden justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/corriges' || isCorrigeRoute, 'text-white degrade3': $route.path == '/corriges' || isCorrigeRoute}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"><path d="M42 20v19a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3h21"/><path d="m16 20l10 8L41 7"/></g></svg>
                <h1 class="text-sm font-bold ml-[10px]">Corrigés</h1>
            </div>
            <div @click="goToExercicesPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/exercices-corriges' || isExercicesRoute, 'text-white degrade3': $route.path == '/exercices-corriges' || isExercicesRoute}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20"><path fill="currentColor" d="M10 6.5V2H5.5A1.5 1.5 0 0 0 4 3.5v5.55Q4.243 9 4.5 9h2c.818 0 1.544.393 2 1h5a.5.5 0 0 1 0 1H8.95l.008.042A2.5 2.5 0 0 1 10.5 12h3a.5.5 0 0 1 0 1h-2.55q.05.243.05.5v.5h2.5a.5.5 0 0 1 0 1H11v2.5q0 .257-.05.5h3.55a1.5 1.5 0 0 0 1.5-1.5V8h-4.5A1.5 1.5 0 0 1 10 6.5m1 0V2.25L15.75 7H11.5a.5.5 0 0 1-.5-.5M3 12v-.5A1.5 1.5 0 0 1 4.5 10h2A1.5 1.5 0 0 1 8 11.5v.5h.5a1.5 1.5 0 0 1 1.5 1.5V15H8v-.5a.5.5 0 0 0-1 0v.5H4v-.5a.5.5 0 0 0-1 0v.5H1v-1.5A1.5 1.5 0 0 1 2.5 12zm4-.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5v.5h3zM8 16h2v1.5A1.5 1.5 0 0 1 8.5 19h-6A1.5 1.5 0 0 1 1 17.5V16h2v.5a.5.5 0 0 0 1 0V16h3v.5a.5.5 0 0 0 1 0z"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Exercices</h1>
            </div>
            <div @click="goToBiblioPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/documents' || isBibliothequesRoute, 'text-white degrade3': $route.path == '/documents' || isBibliothequesRoute}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 256 256"><path fill="currentColor" d="m231.65 194.55l-33.19-157.8a16 16 0 0 0-19-12.39l-46.81 10.06a16.08 16.08 0 0 0-12.3 19l33.19 157.8A16 16 0 0 0 169.16 224a16.3 16.3 0 0 0 3.38-.36l46.81-10.06a16.09 16.09 0 0 0 12.3-19.03M136 50.15v-.09l46.8-10l3.33 15.87L139.33 66Zm6.62 31.47l46.82-10.05l3.34 15.9L146 97.53Zm6.64 31.57l46.82-10.06l13.3 63.24l-46.82 10.06ZM216 197.94l-46.8 10l-3.33-15.87l46.8-10.07l3.33 15.85zM104 32H56a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16M56 48h48v16H56Zm0 32h48v96H56Zm48 128H56v-16h48z"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Bibliothèques</h1>
            </div>
            <div @click="goToActuPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/news', 'text-white degrade3': $route.path == '/news'}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M4 7v12h15v2H4c-2 0-2-2-2-2V7zm17.3-4H7.7C6.76 3 6 3.7 6 4.55v10.9c0 .86.76 1.55 1.7 1.55h13.6c.94 0 1.7-.69 1.7-1.55V4.55C23 3.7 22.24 3 21.3 3M8 5h5v6H8zm13 10H8v-2h13zm0-4h-6V9h6zm0-4h-6V5h6z"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Actualités</h1>
            </div>
            <div @click="goToMembersPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/members', 'text-white degrade3': $route.path == '/members'}">
                <i class="fa-solid fa-users w-[22px] h-[22px]"></i>
                <h1 class="text-sm font-bold ml-[10px]">Membres</h1>
            </div>
            <div @click="goToClientPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/clients', 'text-white degrade3': $route.path == '/clients'}">
                <i class="fa-solid fa-smile w-[22px] h-[22px]"></i>
                <h1 class="text-sm font-bold ml-[10px]">Clients</h1>
            </div>
            <div @click="goToClientPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative hidden justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/subscription', 'text-white degrade3': $route.path == '/subscription'}">
                <i class="fa-solid fa-gift w-[22px] h-[22px]"></i>
                <h1 class="text-sm font-bold ml-[10px]">Abonnements</h1>
            </div>
            <div @click="goToFAQPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/faq', 'text-white degrade3': $route.path == '/faq'}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M11.95 18q.525 0 .888-.363t.362-.887t-.362-.888t-.888-.362t-.887.363t-.363.887t.363.888t.887.362m.05 4q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m.1-14.3q.625 0 1.088.4t.462 1q0 .55-.337.975t-.763.8q-.575.5-1.012 1.1t-.438 1.35q0 .35.263.588t.612.237q.375 0 .638-.25t.337-.625q.1-.525.45-.937t.75-.788q.575-.55.988-1.2t.412-1.45q0-1.275-1.037-2.087T12.1 6q-.95 0-1.812.4T8.975 7.625q-.175.3-.112.638t.337.512q.35.2.725.125t.625-.425q.275-.375.688-.575t.862-.2"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Aide</h1>
            </div>
        </div>
        <div class="w-full absolute bottom-0 left-0 p-[20px] shadow-4xl bg-[#151036]">
            <div @click="goToSettingsPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/settings', 'text-white degrade3': $route.path == '/settings'}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="m10.135 21l-.362-2.892q-.479-.145-1.035-.454q-.557-.31-.947-.664l-2.668 1.135l-1.865-3.25l2.306-1.739q-.045-.27-.073-.558q-.03-.288-.03-.559q0-.252.03-.53q.028-.278.073-.626L3.258 9.126l1.865-3.212L7.771 7.03q.448-.373.97-.673q.52-.3 1.013-.464L10.134 3h3.732l.361 2.912q.575.202 1.016.463t.909.654l2.725-1.115l1.865 3.211l-2.382 1.796q.082.31.092.569t.01.51q0 .233-.02.491q-.019.259-.088.626l2.344 1.758l-1.865 3.25l-2.681-1.154q-.467.393-.94.673t-.985.445L13.866 21zm1.838-6.5q1.046 0 1.773-.727T14.473 12t-.727-1.773t-1.773-.727q-1.052 0-1.776.727T9.473 12t.724 1.773t1.776.727"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Paramètres</h1>
            </div>
            <div @click="handleLogout()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/correction', 'text-white degrade3': $route.path == '/correction'}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M16 13v-2H7V8l-5 4l5 4v-3z"/><path fill="currentColor" d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Se déconnecter</h1>
            </div>
        </div>
    </div>
    <div class="fixed top-0 left-0 w-full bg-white shadow-4xl z-40 md:hidden h-[70px] px-[20px] flex justify-between items-center">
        <div>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/dashboard'">Tableau de bord</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/members'">Les Membres</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/clients'">Liste des Utilisateurs</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/exercices-corriges' || isExercicesRoute">Les Exercices</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/documents' || isBibliothequesRoute">Bibliothèque</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/bibliotheques/tous-les-livres'">Tous les livres</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/epreuves-corriges' || isEpreuveRoute">Les Epreuves</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/news'">Les Actualités</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/settings'">Paramètres</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/subscription'">Les Abonnements</h1>
        </div>
        <div class="text-3xl text-gray-800 cursor-pointer flex" @click="toggleMenu()">
            <span v-if="!isOpen"><i class="fa-solid fa-bars"></i></span>
            <span v-else><i class="fa-solid fa-times"></i></span>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAuthStore } from '@/store/auth';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const handleLogout = () => {
  authStore.logout();
  router.push({ name: 'Login' });
};

const route = useRoute()
const router = useRouter()

const isCorrigeRoute = computed(() => route.path.startsWith('/corriges'))
const isEpreuveRoute = computed(() => route.path.startsWith('/epreuves'))
const isExercicesRoute = computed(() => route.path.startsWith('/exercices'))
const isBibliothequesRoute = computed(() => route.path.startsWith('/documents'))

function goToDashboardPage(){
    router.push("/dashboard")
}
function goToEpreuvesPage() {
    router.push("/epreuves-corriges")
}
function goToCorrigesPage() {
    router.push("/corriges")
}
function goToExercicesPage() {
    router.push("/exercices-corriges")
}
function goToMatieresPage() {
    router.push("/matieres")
}
function goToBiblioPage() {
    router.push("/documents")
}
function goToSettingsPage() {
    router.push("/settings")
}
function goToFAQPage() {
    router.push("/faq")
}
function goToActuPage() {
    router.push("/news")
}
function goToMembersPage() {
    router.push("/members")
}
function goToClientPage() {
    router.push("/clients")
}


const isOpen = ref(false)

function toggleMenu() {
    isOpen.value = !isOpen.value
}
</script>

<style>
</style>